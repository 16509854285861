import { Box, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { CALL_STATUS } from '@allie/utils/src/constants/ecall/call.constants';

import { ReduxStore } from '~/types/redux';

import { CallDetails } from '../CallDetails';

import { CardContent } from './CardContent';
import { ReopenedCallBanner } from './ReopenedCallBanner';
import { UnclaimedButton } from './UnclaimedButton';
import { CallCardContainer, CallCardContentContainer, CallCardProps } from './shared';

export const MobileCallCard = (props: CallCardProps) => {
    const { claimCall, isLoading } = props;
    const [showDetails, setShowDetails] = useState(false);
    const { palette } = useTheme();

    const { userId } = useSelector((state: ReduxStore) => state.session.sessionData);

    const callOwner = props.claimedByUser ?? props.attendedByUser;
    const claimedByUser = callOwner?.id === userId;
    const isOpenCall = props.status === CALL_STATUS.OPEN;

    const handleClick = () => {
        if (!isOpenCall && claimedByUser) {
            setShowDetails((prev) => !prev);
        }
    };

    const handleClaimCall = async () => {
        await claimCall({ callId: props.id });
    };

    return (
        <CallCardContainer>
            <CallCardContentContainer bgcolor={!isOpenCall && !claimedByUser ? palette.grey[50] : undefined}>
                <CardContent onClick={handleClick} showDetails={showDetails} {...props} />

                {isOpenCall && (
                    <Box display="flex" flexDirection="column" gap="8px" padding="0px 16px 16px">
                        {props.hasBeenReopened && <ReopenedCallBanner />}
                        <UnclaimedButton
                            calledAt={props.triggeredAt}
                            unclaimedLevel={props.level}
                            onClick={handleClaimCall}
                            isLoading={isLoading}
                        />
                    </Box>
                )}

                {!isOpenCall && showDetails && !!props.deviceLocation?.type && (
                    <CallDetails
                        callId={props.id}
                        claimedByUser={claimedByUser}
                        status={props.status}
                        resident={props.resident}
                        suggestedLocations={undefined} // TODO - add suggested locations
                        calledAt={props.triggeredAt}
                        startedAt={props.attendedAt ?? undefined}
                        type={props.deviceLocation.type}
                        deviceLocation={props.deviceLocation}
                    />
                )}
            </CallCardContentContainer>
        </CallCardContainer>
    );
};
