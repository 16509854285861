import { Box, Skeleton, Stack, ThemeProvider, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import range from 'lodash/range';
import React, { useMemo, useState } from 'react';

import theme from '~/pages/OperationsV2/theme';

import { RemappedDocumentedCall } from '../../../api/queries/call/getDocumentedCalls';

import { searchQueryAtom, selectedOptionAtom } from './atom';
import { CallCard } from './components/CallCard';
import { CallDetailsBottomSheet } from './components/CallDetailsBottomSheet';
import { SearchResults } from './components/SearchResults';
import { Option } from './components/types';

const removeDuplicates = (options: Option[]) => {
    const uniqueOptions = new Set(options.map((option) => option.id));

    return Array.from(uniqueOptions).map((id) => options.find((option) => option.id === id)!);
};

interface CallsTabProps {
    documentedCalls?: RemappedDocumentedCall[];
    isDocumentedCallsLoading: boolean;
}

export const CallsTab = ({ documentedCalls, isDocumentedCallsLoading }: CallsTabProps) => {
    const [selectedCallId, setSelectedCallId] = useState<number | null>(null);
    const [selectedOption, setSelectedOption] = useAtom(selectedOptionAtom);
    const [searchQuery, setSearchQuery] = useAtom(searchQueryAtom);

    const options = useMemo<Option[]>(() => {
        if (!documentedCalls || !searchQuery) return [];

        const residents = documentedCalls
            .filter((call) => call.resident)
            .map(
                (call) =>
                    ({
                        id: call.resident!.id.toString(),
                        value: call.resident!.name,
                        location: call.resident!.roomNumber
                            ? `Apt ${call.resident!.roomNumber}`
                            : call.deviceLocation?.roomNumber || call.deviceLocation?.zoneName || 'Unknown',
                        type: 'resident',
                    }) satisfies Option
            );

        const staff = documentedCalls
            .filter((call) => call.attendedByUser)
            .map(
                (call) =>
                    ({
                        id: call.attendedByUser.id.toString(),
                        value: call.attendedByUser.name,
                        type: 'staff',
                    }) satisfies Option
            );

        return [...removeDuplicates(residents), ...removeDuplicates(staff)];
    }, [documentedCalls, searchQuery]);

    const documentedCallsToShow = useMemo(() => {
        if (!selectedOption) return documentedCalls;

        if (selectedOption.type === 'resident') {
            return documentedCalls?.filter((call) => call.resident?.id === Number(selectedOption.id));
        }

        return documentedCalls?.filter((call) => call.attendedByUser?.id === Number(selectedOption.id));
    }, [documentedCalls, selectedOption]);

    const handleSelect = (option: Option) => {
        setSelectedOption(option);
        setSearchQuery(option.value);
    };

    if (isDocumentedCallsLoading) {
        return (
            <Stack paddingInline="24px" gap="8px">
                {range(3).map((index) => (
                    <Skeleton key={index} width="100%" height="200px" />
                ))}
            </Stack>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            {searchQuery?.trim() && !selectedOption ? (
                <Box paddingInline="24px">
                    <SearchResults searchQuery={searchQuery} options={options} onSelect={handleSelect} />
                </Box>
            ) : (
                <Stack paddingInline="24px" gap="8px">
                    {documentedCallsToShow?.length === 0 && (
                        // this 80px marginTop is totally arbitrary, but the centralization is tricky
                        // at least it looks good
                        <Stack alignItems="center" justifyContent="center" marginTop="80px">
                            <Typography
                                variant="body1"
                                color="grey.500"
                                fontWeight={700}
                                textAlign="center"
                                sx={{ textWrap: 'balance' }}
                            >
                                There are no done calls at the moment
                            </Typography>
                            <Typography
                                variant="caption"
                                color="grey.500"
                                fontWeight={500}
                                textAlign="center"
                                sx={{ textWrap: 'balance' }}
                            >
                                Check back again when a call has been completed.
                            </Typography>
                        </Stack>
                    )}
                    {documentedCallsToShow?.map((call) => (
                        <CallCard
                            key={call.id}
                            {...call}
                            onClick={setSelectedCallId}
                            selectedCallType={selectedOption?.type ?? null}
                        />
                    ))}
                </Stack>
            )}
            <CallDetailsBottomSheet
                open={!!selectedCallId}
                onClose={() => setSelectedCallId(null)}
                callId={selectedCallId ?? 0}
            />
        </ThemeProvider>
    );
};
