import { Typography, useTheme } from '@mui/material';
import React from 'react';

import { DetailsGroupingDataType } from '@allie/operations-common/src/types/module';

import DataContainer from '~/pages/OperationsV2/components/Module/Details/DataContainer';
import DataTable from '~/pages/OperationsV2/components/Module/Details/DataTable';
import { formatMsToMinutes, formatPercentage } from '~/pages/OperationsV2/utils/format';

type Data = DetailsGroupingDataType<'REGION', 'RISK_AND_COMPLIANCE', 'AVG_RESPONSE_TIME', 'ALL'>;

const Data = ({ data: { regionItems, communityItems } }: { data: Data }) => {
    const { palette } = useTheme();

    return (
        <DataContainer>
            <DataTable
                containerProps={{ sx: { marginBottom: '24px', minWidth: '100%', overflowX: 'auto' } }}
                columns={{
                    regionName: { label: 'Region', allowSort: false, weight: 1 },
                    avgResponseTimeMs: {
                        label: 'Avg. Time',
                        allowSort: false,
                        weight: 1,
                        renderCell: (value) => formatMsToMinutes(value, { fixed: 1 }),
                    },
                    changePercentage: {
                        label: 'Change',
                        allowSort: false,
                        weight: 1,
                        renderCell: (value) => (
                            <Typography color={value > 0 ? (palette.error[700] as string) : undefined}>
                                {formatPercentage(value, { showSign: 'always', signSeparator: ' ' })}
                            </Typography>
                        ),
                    },
                }}
                rows={regionItems}
            />
            <DataTable
                columns={{
                    branchName: { label: 'Community' },
                    avgResponseTimeMs: {
                        label: 'Avg. Time',
                        renderCell: (value) => formatMsToMinutes(value, { fixed: 1 }),
                    },
                    changePercentage: {
                        label: 'Change',
                        renderCell: (value) => (
                            <Typography color={value > 0 ? (palette.error[700] as string) : undefined}>
                                {formatPercentage(value, { showSign: 'always', signSeparator: ' ' })}
                            </Typography>
                        ),
                    },
                }}
                rows={communityItems}
            />
        </DataContainer>
    );
};

export default Data;
