import React from 'react';

import { ShapeProps } from '~/pages/OperationsV2/utils/recharts';

const DataSimpleBarChartBarShape = ({ x, y, width, height, fill }: ShapeProps) => {
    const minHeight = width / 2; // Make sure we render zeroed values as a tiny bar

    const adjustedHeight = Math.max(height, minHeight);
    const adjustedY = height < minHeight ? y - (minHeight - height) : y;

    const radius = Math.min(width, adjustedHeight) / 2;

    return (
        <rect
            x={x}
            y={adjustedY}
            width={width}
            height={adjustedHeight}
            rx={radius}
            ry={radius}
            fill={fill}
            style={{ cursor: 'pointer' }}
        />
    );
};

export default DataSimpleBarChartBarShape;
