import { ComponentOverride } from '.';
import { inputBaseClasses } from '@mui/material/InputBase';
import { textFieldClasses } from '@mui/material/TextField';

export const MuiTextField: ComponentOverride<'MuiTextField'> = {
    defaultProps: {
        variant: 'standard',
    },
    styleOverrides: {
        root: ({ theme: { palette } }) => ({
            [`&.${textFieldClasses.root}`]: {
                backgroundColor: palette.secondary[100] as string,
                borderRadius: '24px',
                border: 'none',
                padding: '6px 12px',
            },

            [`& .${inputBaseClasses.root}`]: {
                paddingInline: 0,

                '::after': {
                    border: 'none !important',
                },
                '::before': {
                    border: 'none !important',
                },

                '&.Mui-disabled': {
                    cursor: 'not-allowed',
                },
            },

            [`& .${inputBaseClasses.input}`]: {
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '140%',
                color: palette.grey[900],
                padding: '6px 0px',

                '&::placeholder': {
                    color: palette.grey[500],
                    opacity: 1,
                },

                '&.Mui-disabled': {
                    cursor: 'not-allowed',
                },
            },
        }),
    },
};
