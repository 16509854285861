import { DateTime } from 'luxon';
import { useCallback } from 'react';

import { api } from '~/api';
import { useBranchId, useErrorHandledQuery, useToken } from '~/api/common';
import { OngoingCall, OngoingCallsResponse } from '~/types/call/call';

export type OngoingCalls = NonNullable<ReturnType<typeof useGetOngoingCalls>['data']>;

interface UseGetOngoingCallsParams {
    careLocationIds?: number[];
    enabled?: boolean;
}

export const useGetOngoingCalls = ({ careLocationIds, enabled = true }: UseGetOngoingCallsParams = {}) => {
    const token = useToken();
    const branchId = useBranchId();

    const queryFn = useCallback(async () => {
        const options = {
            headers: { Authorization: token },
            params: { careLocationIds: careLocationIds?.length ? careLocationIds.join(',') : undefined },
        };

        const { data } = await api.get<OngoingCallsResponse>(`/e-call/${branchId}/call`, options);

        return data.response.map(remapCall);
    }, [token, branchId, careLocationIds]);

    return useErrorHandledQuery({
        queryKey: ['ongoing-calls', branchId, careLocationIds],
        queryFn,
        staleTime: 300000,
        enabled: enabled && !!branchId,
    });
};

const remapCall = (call: OngoingCall) => {
    return {
        ...call,
        triggeredAt: fromISO(call.triggeredAt) as DateTime<true>,
        claimedAt: call.claimedAt ? (fromISO(call.claimedAt) as DateTime<true>) : null,
        attendedAt: call.attendedAt ? (fromISO(call.attendedAt) as DateTime<true>) : null,
        completedAt: call.completedAt ? (fromISO(call.completedAt) as DateTime<true>) : null,
    };
};

const fromISO = (isoDate: string) => DateTime.fromISO(isoDate, { setZone: true });
