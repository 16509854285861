import { Typography, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';

import { CALL_STATUS } from '@allie/utils/src/constants/ecall/call.constants';

import { useTimer } from '~/hooks/useTimer';

import { StatusBadgeContainer } from './shared';

interface StatusBadgeProps {
    calledAt: DateTime;
    attendedAt?: DateTime;
    status: CALL_STATUS;
    claimedByUser: boolean;
}

export const StatusBadge = ({ calledAt, status, attendedAt, claimedByUser }: StatusBadgeProps) => {
    const { palette } = useTheme();
    const timer = useTimer(attendedAt ?? calledAt);

    return (
        <StatusBadgeContainer claimedByUser={claimedByUser}>
            <Typography variant="body1" color={palette.grey[900]} fontWeight={700} lineHeight="18px">
                {status === CALL_STATUS.CLAIMED ? 'Claimed' : 'In Progress'}
            </Typography>
            {!claimedByUser && (
                <Typography variant="body1" color={palette.grey[900]} fontWeight={400}>
                    {status === CALL_STATUS.CLAIMED ? 'Called' : 'Started'} {timer} ago
                </Typography>
            )}
        </StatusBadgeContainer>
    );
};
