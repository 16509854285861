import { Box, styled, useTheme } from '@mui/material';
import { useAtomValue } from 'jotai';
import { DateTime } from 'luxon';
import React, { useCallback, useMemo, useState } from 'react';

import { SectionDataType } from '@allie/operations-common/src/types/module';

import { selectedDateAtom, selectedPeriodAtom } from '~/pages/OperationsV2/atoms';
import DataContainer from '~/pages/OperationsV2/components/Module/Section/DataContainer';
import DataLegend from '~/pages/OperationsV2/components/Module/Section/DataLegend';
import DataMultiLineChart, {
    DataMultiLineChartColorMapping,
} from '~/pages/OperationsV2/components/Module/Section/recharts/DataMultiLineChart';
import { formatDateXAxisLabel } from '~/pages/OperationsV2/components/Module/Section/recharts/shared';
import { formatHours } from '~/pages/OperationsV2/utils/format';

type Item = SectionDataType<'REGION', 'LABOR', 'OVERTIME_AND_AGENCY_SCHEDULED_BY_REGION'>['items'][number];

const ChartContainer = styled(Box)(({ theme: { palette } }) => ({
    backgroundColor: palette.primary[100] as string,
    width: '100%',
    padding: '24px 0 12px',
    borderRadius: '16px',
}));

const Data = ({ items }: { items: Item[] }) => {
    const { palette } = useTheme();

    const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);

    const selectedPeriod = useAtomValue(selectedPeriodAtom);
    const selectedDate = useAtomValue(selectedDateAtom);

    const [focusedDate, setFocusedDate] = useState(selectedDate);

    const isSelected = useCallback(
        (item: Item) => DateTime.fromISO(item.date).hasSame(selectedDate, 'day'),
        [selectedDate]
    );

    const renderXAxisLabel = useCallback(
        (item: Item) => {
            const date = DateTime.fromISO(item.date) as DateTime<true>;
            return formatDateXAxisLabel(selectedPeriod, date, isSelected(item));
        },
        [isSelected]
    );

    const colorMapping: DataMultiLineChartColorMapping = useMemo(
        () => [
            {
                dot: '#1D5EB8',
                line: '#8EB8ED',
            },
            {
                dot: '#D98429',
                line: '#FFB669',
            },
        ],
        [palette]
    );

    const isFocused = useCallback(
        (item: Item) => DateTime.fromISO(item.date).hasSame(focusedDate, 'day'),
        [focusedDate]
    );

    const onFocus = useCallback((item: Item) => {
        if (item.overtimeHours || item.agencyHours) setFocusedDate(DateTime.fromISO(item.date));
    }, []);

    const focusedItem = useMemo(() => items.find(isFocused), [items, isFocused]);

    return (
        <DataContainer>
            <DataLegend
                rows={[
                    {
                        label: 'Overtime',
                        values: [formatHours(focusedItem?.overtimeHours ?? 0)],
                    },
                    {
                        label: 'Agency',
                        values: [formatHours(focusedItem?.agencyHours ?? 0)],
                    },
                ]}
                colorMapping={colorMapping.map(({ dot }) => dot)}
                containerProps={{
                    width: '100%',
                    pt: '12px',
                }}
                isSelected={selectedDate.hasSame(focusedDate, 'day')}
            />
            <ChartContainer ref={setContainerRef}>
                <DataMultiLineChart
                    width={containerRef?.clientWidth ?? 0}
                    height={160}
                    items={items}
                    xAxisDataKey="date"
                    yAxisDataKeys={['overtimeHours', 'agencyHours']}
                    renderXAxisLabel={renderXAxisLabel}
                    colorMapping={colorMapping}
                    isSelected={isSelected}
                    isFocused={isFocused}
                    onFocus={onFocus}
                />
            </ChartContainer>
        </DataContainer>
    );
};

export default Data;
