import { Box, Stack, Typography, alpha, styled, useTheme } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';

import { SectionDataType } from '@allie/operations-common/src/types/module';

import DataContainer from '~/pages/OperationsV2/components/Module/Section/DataContainer';
import DataLegend from '~/pages/OperationsV2/components/Module/Section/DataLegend';
import DataSimpleBarChart, {
    DataSimpleBarChartColorMapping,
} from '~/pages/OperationsV2/components/Module/Section/recharts/DataSimpleBarChart';
import { formatSign } from '~/pages/OperationsV2/utils/format';

type Item = SectionDataType<'REGION', 'LABOR', 'SCHEDULED_VS_BUDGETED_HOURS_BY_BRANCH'>[number];

const ChartContainer = styled(Box)(({ theme: { palette } }) => ({
    backgroundColor: palette.primary[100] as string,
    width: '100%',
    padding: '24px 24px 6px',
    borderRadius: '16px',
    overflowX: 'auto',
}));

const Data = ({ items }: { items: Item[] }) => {
    const { palette } = useTheme();

    const [selectedBranch, setSelectedBranch] = useState<Item | undefined>(items[0]);

    const isSelected = useCallback((item: Item) => item.branchId === selectedBranch?.branchId, [selectedBranch]);
    const onSelect = useCallback((item: Item) => setSelectedBranch(item), [setSelectedBranch]);

    const yAxisDomain = useMemo(() => {
        const remappedItems = items.flatMap((item) => [item.scheduledHours, item.budgetedHours]);

        const dataMin = Math.min(...remappedItems);
        const dataMax = Math.max(...remappedItems);

        // Artificially increase the gap between the bars by cutting off the
        // bottom part of the chart so that it looks better/clearer
        return [dataMin * 0.75, dataMax] as [number, number];
    }, [items]);

    const colorMapping: DataSimpleBarChartColorMapping = useMemo(
        () => ({
            selected: ['#1D5EB8', '#D98429'],
            unselected: [alpha(palette.grey[900], 0.2), alpha(palette.grey[900], 0.1)],
        }),
        [palette]
    );

    if (!selectedBranch) return null;

    return (
        <DataContainer sx={{ gap: '12px' }}>
            <Stack
                sx={({ palette }) => ({
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    pb: '8px',
                    width: '100%',
                    borderBottom: '1px solid',
                    borderColor: palette.grey[500],
                })}
            >
                <Typography>{selectedBranch.branchName}</Typography>
                <Typography
                    color={(selectedBranch.diffHours > 0 ? palette.error[700] : palette.success[500]) as string}
                    fontWeight={700}
                >
                    {`${formatSign(selectedBranch.diffHours, { showSign: 'always', signSeparator: ' ' })} hr`}
                </Typography>
            </Stack>
            <DataLegend
                rows={[
                    {
                        label: 'Scheduled',
                        values: [selectedBranch.scheduledHours],
                    },
                    {
                        label: 'Budgeted',
                        values: [selectedBranch.budgetedHours],
                    },
                ]}
                colorMapping={colorMapping.selected}
                containerProps={{
                    width: '100%',
                    pt: '4px',
                    pb: '8px',
                }}
            />
            <ChartContainer>
                <DataSimpleBarChart
                    height={160}
                    items={items}
                    xAxisDataKey="branchName"
                    yAxisDataKeys={['scheduledHours', 'budgetedHours']}
                    yAxisDomain={yAxisDomain}
                    colorMapping={colorMapping}
                    isSelected={isSelected}
                    onSelect={onSelect}
                />
            </ChartContainer>
        </DataContainer>
    );
};

export default Data;
