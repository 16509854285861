import React from 'react';
import { Bar, BarChart, Cell, XAxis, YAxis } from 'recharts';

import { Item, TickProps } from '~/pages/OperationsV2/utils/recharts';
import { StringKeyOf } from '~/pages/OperationsV2/utils/types';

import DataSimpleBarChartBarShape from './DataSimpleBarChartBarShape';
import DataSimpleBarChartXAxisTick from './DataSimpleBarChartXAxisTick';

const BAR_GAP_PX = 4;
const CATEGORY_GAP_PX = 24;

// Colors for each bar, left to right
export type DataSimpleBarChartColorMapping = Record<'selected' | 'unselected', string[]>;

type DataSimpleBarChartProps<T extends Item, TX extends StringKeyOf<T>> = {
    height: number;
    items: T[];
    xAxisDataKey: TX;
    yAxisDataKeys: StringKeyOf<T>[];
    yAxisDomain?: [number | string, number | string];
    colorMapping: DataSimpleBarChartColorMapping;
    isSelected: (item: T) => boolean;
    onSelect: (item: T) => void;
};

const DataSimpleBarChart = <T extends Item, TX extends StringKeyOf<T>>({
    height,
    items,
    xAxisDataKey,
    yAxisDataKeys,
    yAxisDomain = [0, 'dataMax'],
    colorMapping,
    isSelected,
    onSelect,
}: DataSimpleBarChartProps<T, TX>) => {
    const barWidthPx = 20 - 4 * yAxisDataKeys.length; // e.g. 1 bar = 16px, 2 bars = 12px, 3 bars = 8px, etc.
    const categoryWidthPx = yAxisDataKeys.length * (barWidthPx + BAR_GAP_PX) - BAR_GAP_PX;
    const chartWidthPx = items.length * (categoryWidthPx + CATEGORY_GAP_PX) - CATEGORY_GAP_PX;

    // Don't know why this happens, but it seems like recharts adds 1px per
    // category and 1px per edge, so I'm manually removing these as margins
    const extraMarginWidthPx = items.length + 2;

    return (
        <BarChart
            width={chartWidthPx}
            height={height}
            margin={{ top: 0, right: -extraMarginWidthPx, bottom: 0, left: -extraMarginWidthPx }}
            // margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            data={items}
            barGap={BAR_GAP_PX}
            barCategoryGap={CATEGORY_GAP_PX}
            style={{ userSelect: 'none' }}
        >
            {yAxisDataKeys.map((key, keyIndex) => (
                <Bar
                    key={key}
                    type="linear"
                    dataKey={key}
                    onClick={onSelect}
                    barSize={barWidthPx}
                    shape={DataSimpleBarChartBarShape}
                >
                    {items.map((item, itemIndex) => (
                        <Cell
                            key={itemIndex}
                            fill={colorMapping[isSelected(item) ? 'selected' : 'unselected'][keyIndex]}
                        />
                    ))}
                </Bar>
            ))}
            <XAxis
                dataKey={xAxisDataKey}
                interval={0} // Show all ticks
                padding={{ left: 0, right: 0 }}
                axisLine={false}
                tickLine={false}
                tick={(props: TickProps<T, typeof xAxisDataKey>) => (
                    <DataSimpleBarChartXAxisTick
                        {...props}
                        chartHeight={height}
                        items={items}
                        dataKey={xAxisDataKey}
                        isSelected={isSelected}
                    />
                )}
            />
            <YAxis domain={yAxisDomain} hide />
        </BarChart>
    );
};

export default DataSimpleBarChart;
