type SignOptions = 'always' | 'only-negative' | 'never';

export const formatMsToMinutes = (ms: number, options: { fixed?: number } = {}) => {
    const { fixed = 0 } = options;

    const minutes = (ms / 60000).toFixed(fixed);
    return `${minutes} min`; // e.g. 300000 -> 5 min
};

export const formatHours = (hours: number) => `${hours.toFixed(0)} hr`; // e.g. 123.456 -> 123 hr

export const formatSign = (value: number, options: { showSign?: SignOptions; signSeparator?: string } = {}) => {
    const { showSign = 'only-negative', signSeparator = '' } = options;

    let sign: string;

    switch (showSign) {
        case 'always':
            sign = value >= 0 ? `+${signSeparator}` : `-${signSeparator}`;
            break;
        case 'only-negative':
            sign = value < 0 ? `-${signSeparator}` : '';
            break;
        case 'never':
            sign = '';
            break;
    }

    // e.g. formatSign(123.456, { showSign = 'always', signSeparator = ' ' }) -> + 123.456
    return `${sign}${Math.abs(value)}`;
};

export const formatPercentage = (value: number, options: { showSign?: SignOptions; signSeparator?: string } = {}) => {
    const { showSign = 'only-negative', signSeparator = '' } = options;

    const percentage = +(value * 100).toFixed(0);

    // e.g. formatPercentage(0.1234, { showSign = 'always', signSeparator = ' ' }) -> + 12%
    return `${formatSign(percentage, { showSign, signSeparator })}%`;
};
