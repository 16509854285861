import { useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

import { useClaimCallMutation } from '~/api/queries/call/claimCall';
import { OngoingCalls } from '~/api/queries/call/getOngoingCalls';

import { DesktopCallCard } from './DesktopCallCard';
import { MobileCallCard } from './MobileCallCard';

export const CallCard = (props: OngoingCalls[number]) => {
    const { breakpoints } = useTheme();
    const isDesktop = useMediaQuery(breakpoints.up('sm'));
    const { mutateAsync: claimCall, isPending } = useClaimCallMutation();

    if (isDesktop) {
        return <DesktopCallCard {...props} claimCall={claimCall} isLoading={isPending} />;
    } else {
        return <MobileCallCard {...props} claimCall={claimCall} isLoading={isPending} />;
    }
};
