import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';

export const ReopenedCallBanner = () => {
    const { palette } = useTheme();

    return (
        <Box bgcolor={palette.grey[50]} borderRadius="8px" padding="16px">
            <Typography variant="body1" color={palette.grey[600]} sx={{ textWrap: 'pretty' }}>
                This has become an open call again because it was left unattended for too long.
            </Typography>
        </Box>
    );
};
