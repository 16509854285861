import range from 'lodash/range';
import { DateTime } from 'luxon';

import {
    AnyModuleType,
    AnySectionType,
    DetailsGroupingDataType,
    DetailsGroupingType,
    DetailsType,
    ModuleType,
    SectionDataType,
    SectionType,
} from '@allie/operations-common/src/types/module';
import { Level } from '@allie/operations-common/src/types/shared';

export type LevelData<L extends Level> = {
    type: L;
    modules: { [M in ModuleType<L>]?: ModuleData<L, M> };
};

export type ModuleData<L extends Level, M extends ModuleType<L>> = {
    type: M;
    sections: { [S in SectionType<L, M>]?: SectionData<L, M, S> };
};

export type SectionData<L extends Level, M extends ModuleType<L>, S extends SectionType<L, M>> = {
    type: S;
    data: SectionDataType<L, M, S>;
};

// Helper types for when we really don't care about/cannot get the specific type
export type AnyLevelData = LevelData<Level>;
export type AnyModuleData = ModuleData<Level, AnyModuleType>;
export type AnySectionData = SectionData<Level, AnyModuleType, AnySectionType>;

const regionNames = ['Northeast', 'Northwest', 'Southeast', 'Southwest', 'Central'];

const branchNames = [
    'Willow Haven',
    'Crystal Brook',
    'Amber Grove',
    'Serenity Oaks',
    'Cedar Springs',
    'Heritage Garden',
    'Maple Ridge',
    'Harbor Pines',
    'Silver Creek',
    'Vintage Hills',
];

const residentNames = [
    'Shirley M.',
    'Mary L.',
    'James R.',
    'Helen T.',
    'William P.',
    'Barbara J.',
    'John H.',
    'Margaret S.',
    'Robert W.',
    'Patricia K.',
];

const staffNames = [
    'Michael J.',
    'Emily R.',
    'Daniel T.',
    'Sarah L.',
    'Matthew P.',
    'Anna K.',
    'Christopher H.',
    'Rachel S.',
    'David M.',
    'Laura W.',
];

export const testSectionsData: { [L in Level]?: LevelData<L> } = {
    BRANCH: {
        type: 'BRANCH',
        modules: {
            CALL_BY_RESPONSE_TIME: {
                type: 'CALL_BY_RESPONSE_TIME',
                sections: {
                    AVG_RESPONSE_TIME: {
                        type: 'AVG_RESPONSE_TIME',
                        data: {
                            responseTimeMs: 6 * 60 * 1000,
                            items: [
                                {
                                    date: DateTime.now().minus({ days: 4 }).toISODate(),
                                    responseTimeMs: 6 * 60 * 1000,
                                },
                                {
                                    date: DateTime.now().minus({ days: 3 }).toISODate(),
                                    responseTimeMs: 7 * 60 * 1000,
                                },
                                {
                                    date: DateTime.now().minus({ days: 2 }).toISODate(),
                                    responseTimeMs: 5 * 60 * 1000,
                                },
                                {
                                    date: DateTime.now().minus({ days: 1 }).toISODate(),
                                    responseTimeMs: 6 * 60 * 1000,
                                },
                                {
                                    date: DateTime.now().toISODate(),
                                    responseTimeMs: 5 * 60 * 1000,
                                },
                                {
                                    date: DateTime.now().plus({ days: 1 }).toISODate(),
                                    responseTimeMs: null,
                                },
                                {
                                    date: DateTime.now().plus({ days: 2 }).toISODate(),
                                    responseTimeMs: null,
                                },
                            ],
                        },
                    },
                    AVG_RESPONSE_TIME_BY_CARE_STAFF: {
                        type: 'AVG_RESPONSE_TIME_BY_CARE_STAFF',
                        data: [
                            {
                                staffName: staffNames[7],
                                responseTimeMs: 10 * 60 * 1000,
                                callCount: 10,
                            },
                            {
                                staffName: staffNames[4],
                                responseTimeMs: 8 * 60 * 1000,
                                callCount: 9,
                            },
                            {
                                staffName: staffNames[6],
                                responseTimeMs: 7 * 60 * 1000,
                                callCount: 13,
                            },
                            ...range(25 - 6).map(() => ({
                                staffName: '',
                                responseTimeMs: 0,
                                callCount: 0,
                            })),
                            {
                                staffName: staffNames[2],
                                responseTimeMs: 6 * 60 * 1000,
                                callCount: 7,
                            },
                            {
                                staffName: staffNames[1],
                                responseTimeMs: 5 * 60 * 1000,
                                callCount: 10,
                            },
                            {
                                staffName: staffNames[3],
                                responseTimeMs: 5 * 60 * 1000,
                                callCount: 15,
                            },
                        ],
                    },
                    AVG_RESPONSE_TIME_BY_RESIDENT: {
                        type: 'AVG_RESPONSE_TIME_BY_RESIDENT',
                        data: [
                            {
                                residentName: residentNames[3],
                                responseTimeMs: 10 * 60 * 1000,
                                callCount: 4,
                            },
                            {
                                residentName: residentNames[8],
                                responseTimeMs: 8 * 60 * 1000,
                                callCount: 2,
                            },
                            {
                                residentName: residentNames[5],
                                responseTimeMs: 7 * 60 * 1000,
                                callCount: 8,
                            },
                            ...range(75 - 6).map(() => ({
                                residentName: '',
                                responseTimeMs: 0,
                                callCount: 0,
                            })),
                            {
                                residentName: residentNames[6],
                                responseTimeMs: 6 * 60 * 1000,
                                callCount: 3,
                            },
                            {
                                residentName: residentNames[4],
                                responseTimeMs: 5 * 60 * 1000,
                                callCount: 12,
                            },
                            {
                                residentName: residentNames[2],
                                responseTimeMs: 5 * 60 * 1000,
                                callCount: 5,
                            },
                        ],
                    },
                    LONGEST_RESPONSE_TIME: {
                        type: 'LONGEST_RESPONSE_TIME',
                        data: [
                            {
                                residentName: residentNames[4],
                                staffName: staffNames[4],
                                responseTimeMs: 55 * 60 * 1000,
                            },
                            {
                                residentName: residentNames[5],
                                staffName: staffNames[1],
                                responseTimeMs: 30 * 60 * 1000,
                            },
                            {
                                residentName: residentNames[1],
                                staffName: staffNames[0],
                                responseTimeMs: 25 * 60 * 1000,
                            },
                            {
                                residentName: residentNames[2],
                                staffName: staffNames[8],
                                responseTimeMs: 21 * 60 * 1000,
                            },
                            {
                                residentName: residentNames[9],
                                staffName: staffNames[3],
                                responseTimeMs: 14 * 60 * 1000,
                            },
                        ],
                    },
                },
            },
            CALL_BY_DETAIL: {
                type: 'CALL_BY_DETAIL',
                sections: {
                    TOTAL_CALLS: {
                        type: 'TOTAL_CALLS',
                        data: {
                            totalCount: 19 + 115,
                            emergencyCount: 19,
                            regularCount: 115,
                            items: [
                                {
                                    date: DateTime.now().minus({ days: 4 }).toISODate(),
                                    emergencyCount: 17,
                                    regularCount: 121,
                                },
                                {
                                    date: DateTime.now().minus({ days: 3 }).toISODate(),
                                    emergencyCount: 15,
                                    regularCount: 113,
                                },
                                {
                                    date: DateTime.now().minus({ days: 2 }).toISODate(),
                                    emergencyCount: 22,
                                    regularCount: 108,
                                },
                                {
                                    date: DateTime.now().minus({ days: 1 }).toISODate(),
                                    emergencyCount: 19,
                                    regularCount: 115,
                                },
                                {
                                    date: DateTime.now().toISODate(),
                                    emergencyCount: 20,
                                    regularCount: 116,
                                },
                                {
                                    date: DateTime.now().plus({ days: 1 }).toISODate(),
                                    emergencyCount: 0,
                                    regularCount: 0,
                                },
                                {
                                    date: DateTime.now().plus({ days: 2 }).toISODate(),
                                    emergencyCount: 0,
                                    regularCount: 0,
                                },
                            ],
                        },
                    },
                    EMERGENCY_CALLS: {
                        type: 'EMERGENCY_CALLS',
                        data: {
                            count: 19,
                            items: [
                                {
                                    residentName: residentNames[0],
                                    timestamp: `${DateTime.now().minus({ days: 4 }).toISODate()}T18:00:00`,
                                },
                                {
                                    residentName: residentNames[5],
                                    timestamp: `${DateTime.now().minus({ days: 4 }).toISODate()}T15:00:00`,
                                },
                                {
                                    residentName: residentNames[2],
                                    timestamp: `${DateTime.now().minus({ days: 4 }).toISODate()}T09:00:00`,
                                },
                            ],
                        },
                    },
                    NOT_FEELING_WELL_CALLS: {
                        type: 'NOT_FEELING_WELL_CALLS',
                        data: {
                            count: 115,
                            items: [
                                {
                                    residentName: residentNames[4],
                                    timestamp: `${DateTime.now().minus({ days: 4 }).toISODate()}T19:00:00`,
                                },
                                {
                                    residentName: residentNames[6],
                                    timestamp: `${DateTime.now().minus({ days: 4 }).toISODate()}T16:00:00`,
                                },
                                {
                                    residentName: residentNames[9],
                                    timestamp: `${DateTime.now().minus({ days: 4 }).toISODate()}T08:00:00`,
                                },
                            ],
                        },
                    },
                },
            },
            CALL_BY_RESIDENT: {
                type: 'CALL_BY_RESIDENT',
                sections: {
                    TOTAL_CALLS: {
                        type: 'TOTAL_CALLS',
                        data: {
                            totalCount: 19 + 115,
                            top10Percentage: 34 / (19 + 115),
                            otherPercentage: 100 / (19 + 115),
                            items: [
                                {
                                    date: DateTime.now().minus({ days: 4 }).toISODate(),
                                    top10Percentage: 33 / (17 + 121),
                                    otherPercentage: 105 / (17 + 121),
                                },
                                {
                                    date: DateTime.now().minus({ days: 3 }).toISODate(),
                                    top10Percentage: 35 / (15 + 113),
                                    otherPercentage: 93 / (15 + 113),
                                },
                                {
                                    date: DateTime.now().minus({ days: 2 }).toISODate(),
                                    top10Percentage: 31 / (22 + 108),
                                    otherPercentage: 99 / (22 + 108),
                                },
                                {
                                    date: DateTime.now().minus({ days: 1 }).toISODate(),
                                    top10Percentage: 34 / (19 + 115),
                                    otherPercentage: 100 / (19 + 115),
                                },
                                {
                                    date: DateTime.now().toISODate(),
                                    top10Percentage: 39 / (20 + 116),
                                    otherPercentage: 95 / (20 + 116),
                                },
                                {
                                    date: DateTime.now().plus({ days: 1 }).toISODate(),
                                    top10Percentage: 0,
                                    otherPercentage: 0,
                                },
                                {
                                    date: DateTime.now().plus({ days: 2 }).toISODate(),
                                    top10Percentage: 0,
                                    otherPercentage: 0,
                                },
                            ],
                        },
                    },
                    RESIDENTS_WITH_NEEDS_CHANGES: {
                        type: 'RESIDENTS_WITH_NEEDS_CHANGES',
                        data: {
                            count: 5,
                            items: [
                                {
                                    residentName: residentNames[8],
                                    isNewResident: false,
                                    callCount: 21,
                                    changePercentage: 0.75,
                                },
                                {
                                    residentName: residentNames[6],
                                    isNewResident: true,
                                    callCount: 18,
                                    changePercentage: 0.5,
                                },
                                {
                                    residentName: residentNames[0],
                                    isNewResident: false,
                                    callCount: 13,
                                    changePercentage: 0.3,
                                },
                            ],
                        },
                    },
                    POTENTIAL_CARE_LEVEL_INCREASE: {
                        type: 'POTENTIAL_CARE_LEVEL_INCREASE',
                        data: {
                            count: 6,
                            items: [
                                {
                                    residentName: residentNames[5],
                                    isNewResident: false,
                                    callCount: 21,
                                    careLevel: 2,
                                },
                                {
                                    residentName: residentNames[4],
                                    isNewResident: false,
                                    callCount: 18,
                                    careLevel: 1,
                                },
                                {
                                    residentName: residentNames[2],
                                    isNewResident: true,
                                    callCount: 17,
                                    careLevel: 2,
                                },
                            ],
                        },
                    },
                },
            },
        },
    },
    REGION: {
        type: 'REGION',
        modules: {
            RISK_AND_COMPLIANCE: {
                type: 'RISK_AND_COMPLIANCE',
                sections: {
                    AVG_RESPONSE_TIME: {
                        type: 'AVG_RESPONSE_TIME',
                        data: {
                            regionName: regionNames[0],
                            responseTimeMs: 13.6 * 60 * 1000,
                            items: [
                                {
                                    branchId: 1,
                                    branchName: branchNames[0],
                                    responseTimeMs: 21.2 * 60 * 1000,
                                },
                                {
                                    branchId: 2,
                                    branchName: branchNames[1],
                                    responseTimeMs: 19.4 * 60 * 1000,
                                },
                                {
                                    branchId: 3,
                                    branchName: branchNames[2],
                                    responseTimeMs: 12.8 * 60 * 1000,
                                },
                                {
                                    branchId: 4,
                                    branchName: branchNames[3],
                                    responseTimeMs: 11.6 * 60 * 1000,
                                },
                                {
                                    branchId: 5,
                                    branchName: branchNames[4],
                                    responseTimeMs: 9.9 * 60 * 1000,
                                },
                                {
                                    branchId: 6,
                                    branchName: branchNames[5],
                                    responseTimeMs: 9.8 * 60 * 1000,
                                },
                                {
                                    branchId: 7,
                                    branchName: branchNames[6],
                                    responseTimeMs: 5.7 * 60 * 1000,
                                },
                            ],
                        },
                    },
                    LONGEST_RESPONSE_TIME: {
                        type: 'LONGEST_RESPONSE_TIME',
                        data: [
                            {
                                residentName: residentNames[5],
                                branchName: branchNames[0],
                                responseTimeMs: 25 * 60 * 1000,
                            },
                            {
                                residentName: residentNames[1],
                                branchName: branchNames[1],
                                responseTimeMs: 16 * 60 * 1000,
                            },
                            {
                                residentName: residentNames[9],
                                branchName: branchNames[2],
                                responseTimeMs: 15 * 60 * 1000,
                            },
                            {
                                residentName: residentNames[2],
                                branchName: branchNames[3],
                                responseTimeMs: 14 * 60 * 1000,
                            },
                            {
                                residentName: residentNames[8],
                                branchName: branchNames[4],
                                responseTimeMs: 13 * 60 * 1000,
                            },
                        ],
                    },
                    RESIDENTS_WITH_NEEDS_CHANGES: {
                        type: 'RESIDENTS_WITH_NEEDS_CHANGES',
                        data: [
                            {
                                residentName: residentNames[8],
                                branchName: branchNames[5],
                                isNewResident: false,
                                callCount: 43,
                                changePercentage: 0.4,
                            },
                            {
                                residentName: residentNames[6],
                                branchName: branchNames[6],
                                isNewResident: true,
                                callCount: 36,
                                changePercentage: 0.31,
                            },
                            {
                                residentName: residentNames[0],
                                branchName: branchNames[7],
                                isNewResident: false,
                                callCount: 31,
                                changePercentage: 0.28,
                            },
                        ],
                    },
                    TOTAL_CALLS: {
                        type: 'TOTAL_CALLS',
                        data: [
                            {
                                branchId: 1,
                                branchName: branchNames[8],
                                totalCount: 17 + 121,
                                emergencyCount: 17,
                                emergencyChangePercentage: 0.06,
                                regularCount: 121,
                                regularChangePercentage: -0.08,
                            },
                            {
                                branchId: 2,
                                branchName: branchNames[9],
                                totalCount: 20 + 116,
                                emergencyCount: 20,
                                emergencyChangePercentage: 0.05,
                                regularCount: 116,
                                regularChangePercentage: 0.07,
                            },
                            {
                                branchId: 3,
                                branchName: branchNames[0],
                                totalCount: 15 + 113,
                                emergencyCount: 12,
                                emergencyChangePercentage: -0.06,
                                regularCount: 123,
                                regularChangePercentage: 0.09,
                            },
                            {
                                branchId: 4,
                                branchName: branchNames[1],
                                totalCount: 22 + 108,
                                emergencyCount: 19,
                                emergencyChangePercentage: -0.07,
                                regularCount: 115,
                                regularChangePercentage: 0.08,
                            },
                            {
                                branchId: 5,
                                branchName: branchNames[2],
                                totalCount: 19 + 115,
                                emergencyCount: 14,
                                emergencyChangePercentage: 0.05,
                                regularCount: 120,
                                regularChangePercentage: -0.08,
                            },
                            {
                                branchId: 6,
                                branchName: branchNames[3],
                                totalCount: 20 + 116,
                                emergencyCount: 18,
                                emergencyChangePercentage: 0.06,
                                regularCount: 110,
                                regularChangePercentage: 0.07,
                            },
                            {
                                branchId: 7,
                                branchName: branchNames[4],
                                totalCount: 17 + 121,
                                emergencyCount: 13,
                                emergencyChangePercentage: -0.07,
                                regularCount: 115,
                                regularChangePercentage: -0.08,
                            },
                            {
                                branchId: 8,
                                branchName: branchNames[5],
                                totalCount: 15 + 113,
                                emergencyCount: 15,
                                emergencyChangePercentage: 0.06,
                                regularCount: 113,
                                regularChangePercentage: 0.09,
                            },
                            {
                                branchId: 9,
                                branchName: branchNames[6],
                                totalCount: 11 + 105,
                                emergencyCount: 11,
                                emergencyChangePercentage: 0.05,
                                regularCount: 105,
                                regularChangePercentage: -0.08,
                            },
                            {
                                branchId: 10,
                                branchName: branchNames[7],
                                totalCount: 16 + 108,
                                emergencyCount: 16,
                                emergencyChangePercentage: -0.06,
                                regularCount: 108,
                                regularChangePercentage: 0.07,
                            },
                        ],
                    },
                    INCIDENTS: {
                        type: 'INCIDENTS',
                        data: {
                            regionName: regionNames[0],
                            count: 25,
                            fallItems: [
                                {
                                    branchName: branchNames[8],
                                    count: 2,
                                },
                                {
                                    branchName: branchNames[9],
                                    count: 2,
                                },
                                {
                                    branchName: branchNames[0],
                                    count: 1,
                                },
                            ],
                            onFloorItems: [
                                {
                                    branchName: branchNames[1],
                                    count: 3,
                                },
                                {
                                    branchName: branchNames[2],
                                    count: 2,
                                },
                                {
                                    branchName: branchNames[3],
                                    count: 2,
                                },
                            ],
                            altercationItems: [
                                {
                                    branchName: branchNames[4],
                                    count: 4,
                                },
                                {
                                    branchName: branchNames[5],
                                    count: 3,
                                },
                                {
                                    branchName: branchNames[6],
                                    count: 3,
                                },
                            ],
                        },
                    },
                    MEDICATION: {
                        type: 'MEDICATION',
                        data: {
                            regionName: regionNames[0],
                            count: 28,
                            unavailableItems: [
                                {
                                    branchName: branchNames[7],
                                    count: 3,
                                },
                                {
                                    branchName: branchNames[8],
                                    count: 2,
                                },
                                {
                                    branchName: branchNames[9],
                                    count: 2,
                                },
                            ],
                            refusedItems: [
                                {
                                    branchName: branchNames[0],
                                    count: 6,
                                },
                                {
                                    branchName: branchNames[1],
                                    count: 6,
                                },
                                {
                                    branchName: branchNames[2],
                                    count: 5,
                                },
                            ],
                        },
                    },
                    TRANSFERS: {
                        type: 'TRANSFERS',
                        data: {
                            regionName: regionNames[0],
                            count: 19,
                            nineOneOneItems: [
                                {
                                    branchName: branchNames[3],
                                    count: 3,
                                },
                                {
                                    branchName: branchNames[4],
                                    count: 2,
                                },
                                {
                                    branchName: branchNames[5],
                                    count: 2,
                                },
                            ],
                            medicalItems: [
                                {
                                    branchName: branchNames[6],
                                    count: 4,
                                },
                                {
                                    branchName: branchNames[7],
                                    count: 4,
                                },
                                {
                                    branchName: branchNames[8],
                                    count: 3,
                                },
                            ],
                        },
                    },
                    SCHEDULED_DOCUMENTATION_RATE: {
                        type: 'SCHEDULED_DOCUMENTATION_RATE',
                        data: {
                            regionName: regionNames[0],
                            ratePercentage: 0.8,
                            items: [
                                {
                                    branchId: 1,
                                    branchName: branchNames[9],
                                    ratePercentage: 0.96,
                                },
                                {
                                    branchId: 2,
                                    branchName: branchNames[0],
                                    ratePercentage: 0.92,
                                },
                                {
                                    branchId: 3,
                                    branchName: branchNames[1],
                                    ratePercentage: 0.88,
                                },
                                {
                                    branchId: 4,
                                    branchName: branchNames[2],
                                    ratePercentage: 0.84,
                                },
                                {
                                    branchId: 5,
                                    branchName: branchNames[3],
                                    ratePercentage: 0.8,
                                },
                                {
                                    branchId: 6,
                                    branchName: branchNames[4],
                                    ratePercentage: 0.76,
                                },
                                {
                                    branchId: 7,
                                    branchName: branchNames[5],
                                    ratePercentage: 0.72,
                                },
                                {
                                    branchId: 8,
                                    branchName: branchNames[6],
                                    ratePercentage: 0.68,
                                },
                                {
                                    branchId: 9,
                                    branchName: branchNames[7],
                                    ratePercentage: 0.64,
                                },
                                {
                                    branchId: 10,
                                    branchName: branchNames[8],
                                    ratePercentage: 0.6,
                                },
                            ],
                        },
                    },
                    ECALL_DOCUMENTATION_RATE: {
                        type: 'ECALL_DOCUMENTATION_RATE',
                        data: {
                            regionName: regionNames[0],
                            ratePercentage: 0.8,
                            items: [
                                {
                                    branchId: 1,
                                    branchName: branchNames[7],
                                    ratePercentage: 0.96,
                                },
                                {
                                    branchId: 2,
                                    branchName: branchNames[8],
                                    ratePercentage: 0.92,
                                },
                                {
                                    branchId: 3,
                                    branchName: branchNames[9],
                                    ratePercentage: 0.88,
                                },
                                {
                                    branchId: 4,
                                    branchName: branchNames[0],
                                    ratePercentage: 0.84,
                                },
                                {
                                    branchId: 5,
                                    branchName: branchNames[1],
                                    ratePercentage: 0.8,
                                },
                                {
                                    branchId: 6,
                                    branchName: branchNames[2],
                                    ratePercentage: 0.76,
                                },
                                {
                                    branchId: 7,
                                    branchName: branchNames[3],
                                    ratePercentage: 0.72,
                                },
                                {
                                    branchId: 8,
                                    branchName: branchNames[4],
                                    ratePercentage: 0.68,
                                },
                                {
                                    branchId: 9,
                                    branchName: branchNames[5],
                                    ratePercentage: 0.64,
                                },
                                {
                                    branchId: 10,
                                    branchName: branchNames[6],
                                    ratePercentage: 0.6,
                                },
                            ],
                        },
                    },
                    OVERDUE_ASSESSMENTS: {
                        type: 'OVERDUE_ASSESSMENTS',
                        data: {
                            regionName: regionNames[0],
                            count: 15,
                            items: [
                                {
                                    branchName: branchNames[7],
                                    count: 3,
                                },
                                {
                                    branchName: branchNames[8],
                                    count: 2,
                                },
                                {
                                    branchName: branchNames[9],
                                    count: 2,
                                },
                            ],
                        },
                    },
                    FALL_DOCUMENTATION_INCOMPLETE: {
                        type: 'FALL_DOCUMENTATION_INCOMPLETE',
                        data: {
                            regionName: regionNames[0],
                            count: 12,
                            items: [
                                {
                                    branchName: branchNames[0],
                                    count: 3,
                                },
                                {
                                    branchName: branchNames[1],
                                    count: 2,
                                },
                                {
                                    branchName: branchNames[2],
                                    count: 2,
                                },
                            ],
                        },
                    },
                },
            },
            LABOR: {
                type: 'LABOR',
                sections: {
                    SCHEDULED_VS_BUDGETED_HOURS_BY_REGION: {
                        type: 'SCHEDULED_VS_BUDGETED_HOURS_BY_REGION',
                        data: [
                            {
                                date: DateTime.now().minus({ weeks: 4 }).toISODate(),
                                scheduledHours: 11496,
                                budgetedHours: 10680,
                                diffHours: 11496 - 10680,
                            },
                            {
                                date: DateTime.now().minus({ weeks: 3 }).toISODate(),
                                scheduledHours: 12101,
                                budgetedHours: 11745,
                                diffHours: 12101 - 11745,
                            },
                            {
                                date: DateTime.now().minus({ weeks: 2 }).toISODate(),
                                scheduledHours: 11496,
                                budgetedHours: 11215,
                                diffHours: 11496 - 11215,
                            },
                            {
                                date: DateTime.now().minus({ weeks: 1 }).toISODate(),
                                scheduledHours: 12707,
                                budgetedHours: 12283,
                                diffHours: 12707 - 12283,
                            },
                            {
                                date: DateTime.now().toISODate(),
                                scheduledHours: 11748,
                                budgetedHours: 11496,
                                diffHours: 11748 - 11496,
                            },
                            {
                                date: DateTime.now().plus({ weeks: 1 }).toISODate(),
                                scheduledHours: null,
                                budgetedHours: null,
                                diffHours: null,
                            },
                            {
                                date: DateTime.now().plus({ weeks: 2 }).toISODate(),
                                scheduledHours: null,
                                budgetedHours: null,
                                diffHours: null,
                            },
                        ],
                    },
                    SCHEDULED_VS_BUDGETED_HOURS_BY_BRANCH: {
                        type: 'SCHEDULED_VS_BUDGETED_HOURS_BY_BRANCH',
                        data: [
                            {
                                branchId: 1,
                                branchName: branchNames[0],
                                scheduledHours: 1120,
                                budgetedHours: 968,
                                diffHours: 1120 - 968,
                            },
                            {
                                branchId: 2,
                                branchName: branchNames[1],
                                scheduledHours: 1456,
                                budgetedHours: 1329,
                                diffHours: 1456 - 1329,
                            },
                            {
                                branchId: 3,
                                branchName: branchNames[2],
                                scheduledHours: 1329,
                                budgetedHours: 1318,
                                diffHours: 1329 - 1318,
                            },
                            {
                                branchId: 4,
                                branchName: branchNames[3],
                                scheduledHours: 1222,
                                budgetedHours: 1158,
                                diffHours: 1222 - 1158,
                            },
                            {
                                branchId: 5,
                                branchName: branchNames[4],
                                scheduledHours: 1282,
                                budgetedHours: 1282,
                                diffHours: 1282 - 1282,
                            },
                            {
                                branchId: 6,
                                branchName: branchNames[5],
                                scheduledHours: 1039,
                                budgetedHours: 1041,
                                diffHours: 1039 - 1041,
                            },
                            {
                                branchId: 7,
                                branchName: branchNames[6],
                                scheduledHours: 1439,
                                budgetedHours: 1444,
                                diffHours: 1439 - 1444,
                            },
                            {
                                branchId: 8,
                                branchName: branchNames[7],
                                scheduledHours: 1520,
                                budgetedHours: 1525,
                                diffHours: 1520 - 1525,
                            },
                            {
                                branchId: 9,
                                branchName: branchNames[8],
                                scheduledHours: 1202,
                                budgetedHours: 1210,
                                diffHours: 1202 - 1210,
                            },
                            {
                                branchId: 10,
                                branchName: branchNames[9],
                                scheduledHours: 998,
                                budgetedHours: 1008,
                                diffHours: 998 - 1008,
                            },
                        ],
                    },
                    OVERTIME_AND_AGENCY_SCHEDULED_BY_REGION: {
                        type: 'OVERTIME_AND_AGENCY_SCHEDULED_BY_REGION',
                        data: {
                            overtimeHours: 486,
                            agencyHours: 186,
                            items: [
                                {
                                    date: DateTime.now().minus({ weeks: 4 }).toISODate(),
                                    overtimeHours: 390,
                                    agencyHours: 246,
                                },
                                {
                                    date: DateTime.now().minus({ weeks: 3 }).toISODate(),
                                    overtimeHours: 336,
                                    agencyHours: 230,
                                },
                                {
                                    date: DateTime.now().minus({ weeks: 2 }).toISODate(),
                                    overtimeHours: 444,
                                    agencyHours: 262,
                                },
                                {
                                    date: DateTime.now().minus({ weeks: 1 }).toISODate(),
                                    overtimeHours: 486,
                                    agencyHours: 180,
                                },
                                {
                                    date: DateTime.now().toISODate(),
                                    overtimeHours: 442,
                                    agencyHours: 304,
                                },
                                {
                                    date: DateTime.now().plus({ weeks: 1 }).toISODate(),
                                    overtimeHours: null,
                                    agencyHours: null,
                                },
                                {
                                    date: DateTime.now().plus({ weeks: 2 }).toISODate(),
                                    overtimeHours: null,
                                    agencyHours: null,
                                },
                            ],
                        },
                    },
                    OVERTIME_AND_AGENCY_SCHEDULED_BY_BRANCH: {
                        type: 'OVERTIME_AND_AGENCY_SCHEDULED_BY_BRANCH',
                        data: [
                            {
                                branchId: 1,
                                branchName: branchNames[0],
                                overtimeHours: 24,
                                agencyHours: 80,
                            },
                            {
                                branchId: 2,
                                branchName: branchNames[1],
                                overtimeHours: 102,
                                agencyHours: 8,
                            },
                            {
                                branchId: 3,
                                branchName: branchNames[2],
                                overtimeHours: 72,
                                agencyHours: 32,
                            },
                            {
                                branchId: 4,
                                branchName: branchNames[3],
                                overtimeHours: 48,
                                agencyHours: 56,
                            },
                            {
                                branchId: 5,
                                branchName: branchNames[4],
                                overtimeHours: 64,
                                agencyHours: 0,
                            },
                            {
                                branchId: 6,
                                branchName: branchNames[5],
                                overtimeHours: 56,
                                agencyHours: 0,
                            },
                            {
                                branchId: 7,
                                branchName: branchNames[6],
                                overtimeHours: 36,
                                agencyHours: 10,
                            },
                            {
                                branchId: 8,
                                branchName: branchNames[7],
                                overtimeHours: 36,
                                agencyHours: 0,
                            },
                            {
                                branchId: 9,
                                branchName: branchNames[8],
                                overtimeHours: 28,
                                agencyHours: 0,
                            },
                            {
                                branchId: 10,
                                branchName: branchNames[9],
                                overtimeHours: 0,
                                agencyHours: 16,
                            },
                        ],
                    },
                    HIRING_NEEDS: {
                        type: 'HIRING_NEEDS',
                        data: {
                            regionName: regionNames[0],
                            hiresNeededCount: 32,
                            items: [
                                {
                                    branchName: branchNames[1],
                                    hiresNeededCount: 9,
                                },
                                {
                                    branchName: branchNames[2],
                                    hiresNeededCount: 6,
                                },
                                {
                                    branchName: branchNames[3],
                                    hiresNeededCount: 5,
                                },
                            ],
                        },
                    },
                },
            },
            CARE_REVENUE: {
                type: 'CARE_REVENUE',
                sections: {
                    RESIDENTS_WITH_NEEDS_CHANGES: {
                        type: 'RESIDENTS_WITH_NEEDS_CHANGES',
                        data: {
                            needsChangeCount: 8,
                            regionName: regionNames[0],
                            items: [
                                {
                                    residentName: residentNames[0],
                                    branchName: branchNames[0],
                                    isNewResident: true,
                                    changePercentage: 0.4,
                                    callCount: 43,
                                },
                                {
                                    residentName: residentNames[1],
                                    branchName: branchNames[1],
                                    isNewResident: false,
                                    changePercentage: 0.31,
                                    callCount: 36,
                                },
                                {
                                    residentName: residentNames[2],
                                    branchName: branchNames[2],
                                    isNewResident: true,
                                    changePercentage: 0.28,
                                    callCount: 31,
                                },
                            ],
                        },
                    },
                    POTENTIAL_CARE_LEVEL_INCREASE: {
                        type: 'POTENTIAL_CARE_LEVEL_INCREASE',
                        data: {
                            potentialIncreaseCount: 6,
                            regionName: regionNames[0],
                            items: [
                                {
                                    residentName: residentNames[0],
                                    branchName: branchNames[0],
                                    isNewResident: true,
                                    avgDailyCallCount: 48,
                                    careLevel: 1,
                                },
                                {
                                    residentName: residentNames[1],
                                    branchName: branchNames[1],
                                    isNewResident: false,
                                    avgDailyCallCount: 36,
                                    careLevel: 1,
                                },
                                {
                                    residentName: residentNames[2],
                                    branchName: branchNames[2],
                                    isNewResident: true,
                                    avgDailyCallCount: 24,
                                    careLevel: 1,
                                },
                                {
                                    residentName: residentNames[3],
                                    branchName: branchNames[3],
                                    isNewResident: false,
                                    avgDailyCallCount: 21,
                                    careLevel: 1,
                                },
                            ],
                        },
                    },
                    ON_DEMAND_CARE: {
                        type: 'ON_DEMAND_CARE',
                        data: {
                            demandCareCount: 6,
                            regionName: regionNames[0],
                            items: [
                                {
                                    residentName: residentNames[0],
                                    branchName: branchNames[0],
                                    isNewResident: true,
                                    prnCount: 33,
                                    adlDeviation: 2,
                                },
                                {
                                    residentName: residentNames[1],
                                    branchName: branchNames[1],
                                    isNewResident: false,
                                    prnCount: 28,
                                    adlDeviation: 2,
                                },
                                {
                                    residentName: residentNames[2],
                                    branchName: branchNames[2],
                                    isNewResident: true,
                                    prnCount: 19,
                                    adlDeviation: 3,
                                },
                                {
                                    residentName: residentNames[3],
                                    branchName: branchNames[3],
                                    isNewResident: false,
                                    prnCount: 16,
                                    adlDeviation: 2,
                                },
                                {
                                    residentName: residentNames[4],
                                    branchName: branchNames[4],
                                    isNewResident: true,
                                    prnCount: 10,
                                    adlDeviation: 2,
                                },
                                {
                                    residentName: residentNames[5],
                                    branchName: branchNames[5],
                                    isNewResident: false,
                                    prnCount: 51,
                                    adlDeviation: 4,
                                },
                            ],
                        },
                    },
                    CHANGE_OF_CONDITION: {
                        type: 'CHANGE_OF_CONDITION',
                        data: {
                            regionName: regionNames[0],
                            changeOfConditionCount: 6,
                            items: [
                                {
                                    residentName: residentNames[0],
                                    branchName: branchNames[0],
                                    isNewResident: true,
                                    cocCount: 4,
                                    careType: 'AL',
                                },
                                {
                                    residentName: residentNames[1],
                                    branchName: branchNames[1],
                                    isNewResident: false,
                                    cocCount: 4,
                                    careType: 'MC',
                                },
                                {
                                    residentName: residentNames[2],
                                    branchName: branchNames[2],
                                    isNewResident: true,
                                    cocCount: 3,
                                    careType: 'AL',
                                },
                                {
                                    residentName: residentNames[3],
                                    branchName: branchNames[3],
                                    isNewResident: false,
                                    cocCount: 2,
                                    careType: 'AL',
                                },
                                {
                                    residentName: residentNames[4],
                                    branchName: branchNames[4],
                                    isNewResident: true,
                                    cocCount: 2,
                                    careType: 'MC',
                                },
                                {
                                    residentName: residentNames[5],
                                    branchName: branchNames[5],
                                    isNewResident: false,
                                    cocCount: 2,
                                    careType: 'MC',
                                },
                            ],
                        },
                    },
                    DISTRIBUTION_OF_LEVELS: {
                        type: 'DISTRIBUTION_OF_LEVELS',
                        data: [
                            {
                                locationName: regionNames[0],
                                levelItems: [
                                    {
                                        count: 356,
                                        percentage: 356 / 600,
                                    },
                                    {
                                        count: 103,
                                        percentage: 103 / 600,
                                    },
                                    {
                                        count: 95,
                                        percentage: 95 / 600,
                                    },
                                    {
                                        count: 46,
                                        percentage: 46 / 600,
                                    },
                                ],
                            },
                            {
                                locationName: branchNames[0],
                                levelItems: [
                                    {
                                        count: 56,
                                        percentage: 0.56,
                                    },
                                    {
                                        count: 22,
                                        percentage: 0.22,
                                    },
                                    {
                                        count: 20,
                                        percentage: 0.2,
                                    },
                                    {
                                        count: 2,
                                        percentage: 0.02,
                                    },
                                ],
                            },
                            {
                                locationName: branchNames[1],
                                levelItems: [
                                    {
                                        count: 58,
                                        percentage: 0.58,
                                    },
                                    {
                                        count: 21,
                                        percentage: 0.21,
                                    },
                                    {
                                        count: 20,
                                        percentage: 0.2,
                                    },
                                    {
                                        count: 1,
                                        percentage: 0.01,
                                    },
                                ],
                            },
                            {
                                locationName: branchNames[2],
                                levelItems: [
                                    {
                                        count: 45,
                                        percentage: 0.45,
                                    },
                                    {
                                        count: 19,
                                        percentage: 0.19,
                                    },
                                    {
                                        count: 15,
                                        percentage: 0.15,
                                    },
                                    {
                                        count: 21,
                                        percentage: 0.21,
                                    },
                                ],
                            },
                            {
                                locationName: branchNames[3],
                                levelItems: [
                                    {
                                        count: 49,
                                        percentage: 0.49,
                                    },
                                    {
                                        count: 17,
                                        percentage: 0.17,
                                    },
                                    {
                                        count: 21,
                                        percentage: 0.21,
                                    },
                                    {
                                        count: 13,
                                        percentage: 0.13,
                                    },
                                ],
                            },
                            {
                                locationName: branchNames[4],
                                levelItems: [
                                    {
                                        count: 69,
                                        percentage: 0.69,
                                    },
                                    {
                                        count: 13,
                                        percentage: 0.13,
                                    },
                                    {
                                        count: 14,
                                        percentage: 0.14,
                                    },
                                    {
                                        count: 4,
                                        percentage: 0.04,
                                    },
                                ],
                            },
                            {
                                locationName: branchNames[4],
                                levelItems: [
                                    {
                                        count: 79,
                                        percentage: 0.79,
                                    },
                                    {
                                        count: 11,
                                        percentage: 0.11,
                                    },
                                    {
                                        count: 5,
                                        percentage: 0.05,
                                    },
                                    {
                                        count: 5,
                                        percentage: 0.05,
                                    },
                                ],
                            },
                        ],
                    },
                },
            },
        },
    },
};

const testDetailsData: {
    [L in Level]?: {
        [M in ModuleType<L>]?: {
            [D in DetailsType<L, M>]?: {
                [G in DetailsGroupingType<L, M, D>]?: DetailsGroupingDataType<L, M, D, G>;
            };
        };
    };
} = {
    BRANCH: {
        CALL_BY_RESPONSE_TIME: {
            AVG_RESPONSE_TIME_BY: {
                SHIFT: [
                    {
                        shiftName: 'AM',
                        responseTimeMs: 6 * 60 * 1000,
                        callCount: 64,
                    },
                    {
                        shiftName: 'PM',
                        responseTimeMs: 6 * 60 * 1000,
                        callCount: 47,
                    },
                    {
                        shiftName: 'NOC',
                        responseTimeMs: 5 * 60 * 1000,
                        callCount: 23,
                    },
                ],
                DAYS: [
                    {
                        date: DateTime.now().minus({ days: 8 }).toISODate(),
                        responseTimeMs: 5 * 60 * 1000,
                        callCount: 20 + 116,
                    },
                    {
                        date: DateTime.now().minus({ days: 7 }).toISODate(),
                        responseTimeMs: 6 * 60 * 1000,
                        callCount: 18 + 119,
                    },
                    {
                        date: DateTime.now().minus({ days: 6 }).toISODate(),
                        responseTimeMs: 5 * 60 * 1000,
                        callCount: 13 + 122,
                    },
                    {
                        date: DateTime.now().minus({ days: 5 }).toISODate(),
                        responseTimeMs: 7 * 60 * 1000,
                        callCount: 16 + 118,
                    },
                    {
                        date: DateTime.now().minus({ days: 4 }).toISODate(),
                        responseTimeMs: 6 * 60 * 1000,
                        callCount: 17 + 121,
                    },
                    {
                        date: DateTime.now().minus({ days: 3 }).toISODate(),
                        responseTimeMs: 7 * 60 * 1000,
                        callCount: 15 + 113,
                    },
                    {
                        date: DateTime.now().minus({ days: 2 }).toISODate(),
                        responseTimeMs: 5 * 60 * 1000,
                        callCount: 22 + 108,
                    },
                    {
                        date: DateTime.now().minus({ days: 1 }).toISODate(),
                        responseTimeMs: 6 * 60 * 1000,
                        callCount: 19 + 115,
                    },
                    {
                        date: DateTime.now().toISODate(),
                        responseTimeMs: 5 * 60 * 1000,
                        callCount: 20 + 116,
                    },
                ],
                CARE_STAFF: [
                    {
                        staffName: staffNames[9],
                        responseTimeMs: 9 * 60 * 1000,
                        callCount: 15,
                    },
                    {
                        staffName: staffNames[8],
                        responseTimeMs: 9 * 60 * 1000,
                        callCount: 10,
                    },
                    {
                        staffName: staffNames[7],
                        responseTimeMs: 8 * 60 * 1000,
                        callCount: 9,
                    },
                    {
                        staffName: staffNames[6],
                        responseTimeMs: 7 * 60 * 1000,
                        callCount: 15,
                    },
                    {
                        staffName: staffNames[5],
                        responseTimeMs: 7 * 60 * 1000,
                        callCount: 17,
                    },
                    {
                        staffName: staffNames[4],
                        responseTimeMs: 6 * 60 * 1000,
                        callCount: 12,
                    },
                    {
                        staffName: staffNames[3],
                        responseTimeMs: 6 * 60 * 1000,
                        callCount: 15,
                    },
                    {
                        staffName: staffNames[2],
                        responseTimeMs: 5 * 60 * 1000,
                        callCount: 18,
                    },
                    {
                        staffName: staffNames[1],
                        responseTimeMs: 5 * 60 * 1000,
                        callCount: 11,
                    },
                    {
                        staffName: staffNames[0],
                        responseTimeMs: 5 * 60 * 1000,
                        callCount: 12,
                    },
                ],
                RESIDENT: [
                    {
                        residentName: residentNames[9],
                        responseTimeMs: 8 * 60 * 1000,
                        callCount: 9,
                    },
                    {
                        residentName: residentNames[8],
                        responseTimeMs: 8 * 60 * 1000,
                        callCount: 11,
                    },
                    {
                        residentName: residentNames[7],
                        responseTimeMs: 7 * 60 * 1000,
                        callCount: 8,
                    },
                    {
                        residentName: residentNames[6],
                        responseTimeMs: 7 * 60 * 1000,
                        callCount: 10,
                    },
                    {
                        residentName: residentNames[5],
                        responseTimeMs: 6 * 60 * 1000,
                        callCount: 12,
                    },
                    {
                        residentName: residentNames[4],
                        responseTimeMs: 6 * 60 * 1000,
                        callCount: 7,
                    },
                    {
                        residentName: residentNames[3],
                        responseTimeMs: 6 * 60 * 1000,
                        callCount: 13,
                    },
                    {
                        residentName: residentNames[2],
                        responseTimeMs: 5 * 60 * 1000,
                        callCount: 8,
                    },
                    {
                        residentName: residentNames[1],
                        responseTimeMs: 5 * 60 * 1000,
                        callCount: 12,
                    },
                    {
                        residentName: residentNames[0],
                        responseTimeMs: 5 * 60 * 1000,
                        callCount: 10,
                    },
                ],
            },
            LONGEST_RESPONSE_TIME: {
                ALL: [
                    {
                        residentName: residentNames[5],
                        staffName: staffNames[4],
                        responseTimeMs: 55 * 60 * 1000,
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 2, minutes: 7 }).toISO(),
                        managerNotes:
                            'The resident accidentally pressed the e-call and then took a walk out of the building with her family.',
                    },
                    {
                        residentName: residentNames[5],
                        staffName: staffNames[4],
                        responseTimeMs: 30 * 60 * 1000,
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 8, minutes: 43 }).toISO(),
                        managerNotes:
                            'The new caregiver provided the service but forgot to click the e-call button to mark it complete.',
                    },
                    {
                        residentName: residentNames[8],
                        staffName: staffNames[4],
                        responseTimeMs: 25 * 60 * 1000,
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 9, minutes: 23 }).toISO(),
                        managerNotes: null,
                    },
                    {
                        residentName: residentNames[5],
                        staffName: staffNames[6],
                        responseTimeMs: 21 * 60 * 1000,
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 13, minutes: 34 }).toISO(),
                        managerNotes: null,
                    },
                    {
                        residentName: residentNames[9],
                        staffName: staffNames[0],
                        responseTimeMs: 14 * 60 * 1000,
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 1, minutes: 45 }).toISO(),
                        managerNotes:
                            'We had an emergency in the building, and the staff was busy working with the officials on reporting.',
                    },
                    {
                        residentName: residentNames[3],
                        staffName: staffNames[2],
                        responseTimeMs: 12 * 60 * 1000,
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 15, minutes: 56 }).toISO(),
                        managerNotes: null,
                    },
                    {
                        residentName: residentNames[2],
                        staffName: staffNames[9],
                        responseTimeMs: 11 * 60 * 1000,
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 18, minutes: 12 }).toISO(),
                        managerNotes: null,
                    },
                    {
                        residentName: residentNames[1],
                        staffName: staffNames[7],
                        responseTimeMs: 10 * 60 * 1000,
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 16, minutes: 7 }).toISO(),
                        managerNotes:
                            'The resident left their walker in the hallway, and staff found it while making rounds.',
                    },
                    {
                        residentName: residentNames[6],
                        staffName: staffNames[4],
                        responseTimeMs: 8 * 60 * 1000,
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 5, minutes: 23 }).toISO(),
                        managerNotes: null,
                    },
                    {
                        residentName: residentNames[8],
                        staffName: staffNames[3],
                        responseTimeMs: 8 * 60 * 1000,
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 10, minutes: 2 }).toISO(),
                        managerNotes: null,
                    },
                ],
            },
        },
        CALL_BY_DETAIL: {
            ALL: {
                EMERGENCY: [
                    {
                        residentName: residentNames[0],
                        staffName: staffNames[0],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 8, minutes: 43 }).toISO(),
                        type: 'Fall',
                        responseTimeMs: 41 * 60 * 1000,
                        staffNotes:
                            'She refused to change into clean clothes after a shower, insisting her favorite dress was still clean, though it was visibly soiled. She later asked me to hang the dress back in her closet.',
                        managerNotes: null,
                    },
                    {
                        residentName: residentNames[2],
                        staffName: staffNames[3],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 18, minutes: 12 }).toISO(),
                        type: '911',
                        responseTimeMs: 35 * 60 * 1000,
                        staffNotes: null,
                        managerNotes:
                            'The resident called the front desk by mistake while adjusting their phone settings.',
                    },
                    {
                        residentName: residentNames[9],
                        staffName: staffNames[8],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 9, minutes: 23 }).toISO(),
                        type: 'Fall',
                        responseTimeMs: 32 * 60 * 1000,
                        staffNotes: null,
                        managerNotes: null,
                    },
                    {
                        residentName: residentNames[5],
                        staffName: staffNames[8],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 13, minutes: 34 }).toISO(),
                        type: 'Health',
                        responseTimeMs: 25 * 60 * 1000,
                        staffNotes:
                            'The resident left their walker in the hallway, and I found it while making rounds.',
                        managerNotes: null,
                    },
                    {
                        residentName: residentNames[4],
                        staffName: staffNames[2],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 14, minutes: 45 }).toISO(),
                        type: 'Fall',
                        responseTimeMs: 20 * 60 * 1000,
                        staffNotes: null,
                        managerNotes: null,
                    },
                    {
                        residentName: residentNames[3],
                        staffName: staffNames[1],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 15, minutes: 56 }).toISO(),
                        type: 'Safety',
                        responseTimeMs: 18 * 60 * 1000,
                        staffNotes: null,
                        managerNotes:
                            'A routine fire drill was completed, and residents followed the process smoothly.',
                    },
                ],
                NOT_FEELING_WELL: [
                    {
                        residentName: residentNames[8],
                        staffName: staffNames[9],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 8, minutes: 43 }).toISO(),
                        responseTimeMs: 27 * 60 * 1000,
                        staffNotes:
                            'The resident seemed more confused than usual. She urinated a lot. She took off her wet underpants and threw them out in her living room. She called the staff in her room a few times to tell me that her daughter would be here soon.',
                        managerNotes: null,
                        potentialPattern: null,
                    },
                    {
                        residentName: residentNames[2],
                        staffName: staffNames[0],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 18, minutes: 12 }).toISO(),
                        responseTimeMs: 32 * 60 * 1000,
                        staffNotes:
                            'Resident is in a lot of pain. The pain seems much worse than it has been. She just wanted to hold my hands for some comfort. She did not want vitals done but mentioned a PRN Percocet, which QMAP gave them.',
                        managerNotes: null,
                        potentialPattern: '4x last 7 days | 6x last 30 days | 4x on AM shift',
                    },
                    {
                        residentName: residentNames[6],
                        staffName: staffNames[2],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 9, minutes: 23 }).toISO(),
                        responseTimeMs: 18 * 60 * 1000,
                        staffNotes: 'The resident was moaning in pain when we tried to change them.',
                        managerNotes: null,
                        potentialPattern: '2x today | 3x last 7 days | 2x on PM shift',
                    },
                    {
                        residentName: residentNames[5],
                        staffName: staffNames[3],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 13, minutes: 34 }).toISO(),
                        responseTimeMs: 12 * 60 * 1000,
                        staffNotes: null,
                        managerNotes: null,
                        potentialPattern: null,
                    },
                    {
                        residentName: residentNames[4],
                        staffName: staffNames[4],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 14, minutes: 45 }).toISO(),
                        responseTimeMs: 15 * 60 * 1000,
                        staffNotes:
                            'Resident was finding it difficult to walk, they were complaining of pains in both legs.',
                        managerNotes: null,
                        potentialPattern: '2x last 7 days | 2x on NOC shift',
                    },
                    {
                        residentName: residentNames[7],
                        staffName: staffNames[6],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 16, minutes: 7 }).toISO(),
                        responseTimeMs: 12 * 60 * 1000,
                        staffNotes: null,
                        managerNotes: null,
                        potentialPattern: null,
                    },
                    {
                        residentName: residentNames[9],
                        staffName: staffNames[7],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 15, minutes: 56 }).toISO(),
                        responseTimeMs: 10 * 60 * 1000,
                        staffNotes: null,
                        managerNotes: null,
                        potentialPattern: '3rd time this month',
                    },
                ],
                OTHER: [
                    {
                        residentName: residentNames[6],
                        staffName: staffNames[3],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 8, minutes: 43 }).toISO(),
                        responseTimeMs: 27 * 60 * 1000,
                        notableEvents: 'Toileting (1)',
                        staffNotes:
                            'The resident seemed more confused than usual. She urinated a lot. She took off her wet underpants and threw them out in her living room. She called the staff in her room a few times to tell me that her daughter would be here soon.',
                        managerNotes: null,
                        potentialPattern: null,
                        type: 'PRN',
                    },
                    {
                        residentName: residentNames[7],
                        staffName: staffNames[2],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 18, minutes: 12 }).toISO(),
                        responseTimeMs: 15 * 60 * 1000,
                        notableEvents: null,
                        staffNotes: null,
                        managerNotes: null,
                        potentialPattern: null,
                        type: 'Scheduled',
                    },
                    {
                        residentName: residentNames[3],
                        staffName: staffNames[0],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 9, minutes: 23 }).toISO(),
                        responseTimeMs: 20 * 60 * 1000,
                        notableEvents: 'Transfer (2 person assist)',
                        staffNotes:
                            'He insisted on getting up from the chair to take a walk - almost fell. We needed two people to assist to get him back into his chair.',
                        managerNotes: null,
                        potentialPattern: '2nd time this week',
                        type: 'PRN',
                    },
                    {
                        residentName: residentNames[9],
                        staffName: staffNames[5],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 13, minutes: 34 }).toISO(),
                        responseTimeMs: 12 * 60 * 1000,
                        notableEvents: null,
                        staffNotes: null,
                        managerNotes: null,
                        potentialPattern: null,
                        type: 'PRN',
                    },
                    {
                        residentName: residentNames[7],
                        staffName: staffNames[4],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 14, minutes: 45 }).toISO(),
                        responseTimeMs: 17 * 60 * 1000,
                        notableEvents: 'Transfer (1), Housekeeping (1)',
                        staffNotes:
                            'He wanted me to transfer to the dinner hall for dinner but then asked me to find his wallet, fix one leg of his coffee table, pour out his urine, reorganize his clothes ,and help him figure out why his cellphone could not call his daughter.',
                        managerNotes: null,
                        potentialPattern: null,
                        type: 'Scheduled',
                    },
                    {
                        residentName: residentNames[6],
                        staffName: staffNames[3],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 15, minutes: 56 }).toISO(),
                        responseTimeMs: 10 * 60 * 1000,
                        notableEvents: null,
                        staffNotes: null,
                        managerNotes: null,
                        potentialPattern: '3rd time this month',
                        type: 'PRN',
                    },
                    {
                        residentName: residentNames[8],
                        staffName: staffNames[2],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 16, minutes: 7 }).toISO(),
                        responseTimeMs: 8 * 60 * 1000,
                        notableEvents: null,
                        staffNotes: null,
                        managerNotes: null,
                        potentialPattern: null,
                        type: 'Scheduled',
                    },
                ],
            },
        },
        CALL_BY_RESIDENT: {
            ALL: {
                HEAVY_HITTER: {
                    totalCount: 15 + 12 + 8 + 13 + 7 + 12 + 10,
                    items: [
                        {
                            residentName: residentNames[2],
                            callCount: 15,
                            avgTimePerShiftMs: 15 * 5 * 60 * 1000,
                            careLevel: 2,
                            assessmentDate: DateTime.now().minus({ days: 4 }).minus({ days: 10 }).toISODate(),
                            allieCocScore: 2,
                        },
                        {
                            residentName: residentNames[0],
                            callCount: 12,
                            avgTimePerShiftMs: 12 * 5 * 60 * 1000,
                            careLevel: 2,
                            assessmentDate: DateTime.now().minus({ days: 3 }).minus({ days: 13 }).toISODate(),
                            allieCocScore: 2,
                        },
                        {
                            residentName: residentNames[5],
                            callCount: 8,
                            avgTimePerShiftMs: 8 * 5 * 60 * 1000,
                            careLevel: 3,
                            assessmentDate: DateTime.now().minus({ days: 2 }).minus({ days: 15 }).toISODate(),
                            allieCocScore: 3,
                        },
                        {
                            residentName: residentNames[8],
                            callCount: 13,
                            avgTimePerShiftMs: 13 * 5 * 60 * 1000,
                            careLevel: 2,
                            assessmentDate: DateTime.now().minus({ days: 1 }).minus({ days: 12 }).toISODate(),
                            allieCocScore: 2,
                        },
                        {
                            residentName: residentNames[7],
                            callCount: 7,
                            avgTimePerShiftMs: 7 * 5 * 60 * 1000,
                            careLevel: 3,
                            assessmentDate: DateTime.now().minus({ days: 14 }).toISODate(),
                            allieCocScore: 3,
                        },
                        {
                            residentName: residentNames[9],
                            callCount: 12,
                            avgTimePerShiftMs: 12 * 5 * 60 * 1000,
                            careLevel: 2,
                            assessmentDate: DateTime.now().plus({ days: 1 }).minus({ days: 11 }).toISODate(),
                            allieCocScore: 2,
                        },
                        {
                            residentName: residentNames[6],
                            callCount: 10,
                            avgTimePerShiftMs: 10 * 5 * 60 * 1000,
                            careLevel: 2,
                            assessmentDate: DateTime.now().plus({ days: 2 }).minus({ days: 16 }).toISODate(),
                            allieCocScore: 2,
                        },
                    ],
                },
                ANOMALY: [
                    {
                        residentName: residentNames[5],
                        changePercentage: +0.25,
                        callCount: 12 * 1.25,
                        prevCallCount: 12,
                        avgTimePerShiftMs: 12 * 5 * 60 * 1000,
                        careLevel: 2,
                        assessmentDate: DateTime.now().minus({ days: 4 }).minus({ days: 10 }).toISODate(),
                        allieCocScore: 2,
                        allieNotes: null,
                    },
                    {
                        residentName: residentNames[2],
                        changePercentage: -(1 / 3),
                        callCount: 18 * (2 / 3),
                        prevCallCount: 18,
                        avgTimePerShiftMs: 18 * 5 * 60 * 1000,
                        careLevel: 2,
                        assessmentDate: DateTime.now().minus({ days: 3 }).minus({ days: 13 }).toISODate(),
                        allieCocScore: 3,
                        allieNotes: '2+ falls in last 30 days',
                    },
                    {
                        residentName: residentNames[3],
                        changePercentage: +0.1,
                        callCount: 10 * 1.1,
                        prevCallCount: 10,
                        avgTimePerShiftMs: 10 * 5 * 60 * 1000,
                        careLevel: 3,
                        assessmentDate: DateTime.now().minus({ days: 2 }).minus({ days: 15 }).toISODate(),
                        allieCocScore: 2,
                        allieNotes: null,
                    },
                    {
                        residentName: residentNames[8],
                        changePercentage: -0.3,
                        callCount: 20 * 0.7,
                        prevCallCount: 20,
                        avgTimePerShiftMs: 20 * 5 * 60 * 1000,
                        careLevel: 2,
                        assessmentDate: DateTime.now().minus({ days: 1 }).minus({ days: 12 }).toISODate(),
                        allieCocScore: 2,
                        allieNotes: null,
                    },
                    {
                        residentName: residentNames[6],
                        changePercentage: +(1 / 3),
                        callCount: 12 * (4 / 3),
                        prevCallCount: 12,
                        avgTimePerShiftMs: 12 * 5 * 60 * 1000,
                        careLevel: 3,
                        assessmentDate: DateTime.now().minus({ days: 14 }).toISODate(),
                        allieCocScore: 3,
                        allieNotes: '2+ incontinence in last 7 days',
                    },
                    {
                        residentName: residentNames[1],
                        changePercentage: -0.2,
                        callCount: 15 * 0.8,
                        prevCallCount: 15,
                        avgTimePerShiftMs: 15 * 5 * 60 * 1000,
                        careLevel: 2,
                        assessmentDate: DateTime.now().plus({ days: 1 }).minus({ days: 11 }).toISODate(),
                        allieCocScore: 2,
                        allieNotes: null,
                    },
                    {
                        residentName: residentNames[0],
                        changePercentage: +0.3,
                        callCount: 10 * 1.3,
                        prevCallCount: 10,
                        avgTimePerShiftMs: 10 * 5 * 60 * 1000,
                        careLevel: 2,
                        assessmentDate: DateTime.now().plus({ days: 2 }).minus({ days: 16 }).toISODate(),
                        allieCocScore: 2,
                        allieNotes: null,
                    },
                ],
                CARE_INCREASE: [
                    {
                        residentName: residentNames[7],
                        callCount: 10,
                        avgTimePerShiftMs: 10 * 5 * 60 * 1000,
                        careLevel: 2,
                        assessmentDate: DateTime.now().minus({ days: 4 }).minus({ days: 10 }).toISODate(),
                        allieCocScore: 2,
                        allieNotes: null,
                    },
                    {
                        residentName: residentNames[4],
                        callCount: 12,
                        avgTimePerShiftMs: 12 * 5 * 60 * 1000,
                        careLevel: 2,
                        assessmentDate: DateTime.now().minus({ days: 3 }).minus({ days: 13 }).toISODate(),
                        allieCocScore: 2,
                        allieNotes: '2+ falls in last 30 days',
                    },
                    {
                        residentName: residentNames[3],
                        callCount: 8,
                        avgTimePerShiftMs: 8 * 5 * 60 * 1000,
                        careLevel: 3,
                        assessmentDate: DateTime.now().minus({ days: 2 }).minus({ days: 15 }).toISODate(),
                        allieCocScore: 3,
                        allieNotes: null,
                    },
                    {
                        residentName: residentNames[9],
                        callCount: 13,
                        avgTimePerShiftMs: 13 * 5 * 60 * 1000,
                        careLevel: 2,
                        assessmentDate: DateTime.now().minus({ days: 1 }).minus({ days: 12 }).toISODate(),
                        allieCocScore: 2,
                        allieNotes: '2+ incontinence in last 7 days',
                    },
                    {
                        residentName: residentNames[2],
                        callCount: 7,
                        avgTimePerShiftMs: 7 * 5 * 60 * 1000,
                        careLevel: 3,
                        assessmentDate: DateTime.now().minus({ days: 14 }).toISODate(),
                        allieCocScore: 3,
                        allieNotes: null,
                    },
                    {
                        residentName: residentNames[5],
                        callCount: 12,
                        avgTimePerShiftMs: 12 * 5 * 60 * 1000,
                        careLevel: 2,
                        assessmentDate: DateTime.now().plus({ days: 1 }).minus({ days: 11 }).toISODate(),
                        allieCocScore: 2,
                        allieNotes: null,
                    },
                    {
                        residentName: residentNames[0],
                        callCount: 10,
                        avgTimePerShiftMs: 10 * 5 * 60 * 1000,
                        careLevel: 2,
                        assessmentDate: DateTime.now().plus({ days: 2 }).minus({ days: 16 }).toISODate(),
                        allieCocScore: 2,
                        allieNotes: null,
                    },
                ],
                ALL: [
                    {
                        residentName: residentNames[3],
                        callCount: 13,
                    },
                    {
                        residentName: residentNames[5],
                        callCount: 12,
                    },
                    {
                        residentName: residentNames[1],
                        callCount: 12,
                    },
                    {
                        residentName: residentNames[8],
                        callCount: 11,
                    },
                    {
                        residentName: residentNames[6],
                        callCount: 10,
                    },
                    {
                        residentName: residentNames[0],
                        callCount: 10,
                    },
                    {
                        residentName: residentNames[2],
                        callCount: 8,
                    },
                    {
                        residentName: residentNames[7],
                        callCount: 8,
                    },
                    {
                        residentName: residentNames[4],
                        callCount: 7,
                    },
                    {
                        residentName: residentNames[9],
                        callCount: 9,
                    },
                ],
            },
        },
    },
    REGION: {
        RISK_AND_COMPLIANCE: {
            AVG_RESPONSE_TIME: {
                ALL: {
                    regionItems: [
                        {
                            regionName: regionNames[0],
                            avgResponseTimeMs: 13.6 * 60 * 1000,
                            changePercentage: 0.03,
                        },
                    ],
                    communityItems: [
                        {
                            branchName: branchNames[0],
                            avgResponseTimeMs: 21.2 * 60 * 1000,
                            changePercentage: 0.04,
                        },
                        {
                            branchName: branchNames[1],
                            avgResponseTimeMs: 19.4 * 60 * 1000,
                            changePercentage: 0.17,
                        },
                        {
                            branchName: branchNames[2],
                            avgResponseTimeMs: 12.8 * 60 * 1000,
                            changePercentage: 0.02,
                        },
                        {
                            branchName: branchNames[3],
                            avgResponseTimeMs: 11.6 * 60 * 1000,
                            changePercentage: 0.05,
                        },
                        {
                            branchName: branchNames[4],
                            avgResponseTimeMs: 9.9 * 60 * 1000,
                            changePercentage: -0.11,
                        },
                        {
                            branchName: branchNames[5],
                            avgResponseTimeMs: 9.8 * 60 * 1000,
                            changePercentage: -0.07,
                        },
                        {
                            branchName: branchNames[6],
                            avgResponseTimeMs: 5.7 * 60 * 1000,
                            changePercentage: -0.03,
                        },
                    ],
                },
            },
            LONGEST_RESPONSE_TIME: {
                ALL: [
                    {
                        responseTimeInMs: 25 * 60 * 1000,
                        residentName: residentNames[5],
                        branchName: branchNames[0],
                        staffName: staffNames[0],
                        timestamp: DateTime.now().minus({ days: 2 }).set({ hour: 14, minute: 23 }).toISO(),
                        managerNotes:
                            "Another Resident had a medical emergency that Care staff were attending. Shirley simply wanted some help to find her TV remote. The care staff prioritized the other resident's incident because of it.",
                    },
                    {
                        responseTimeInMs: 16 * 60 * 1000,
                        residentName: residentNames[1],
                        branchName: branchNames[0],
                        staffName: staffNames[1],
                        timestamp: DateTime.now().minus({ days: 3 }).set({ hour: 12, minute: 39 }).toISO(),
                        managerNotes:
                            "The staff forgot to dismiss the call after she got to the resident's room. The staff arrived at the room within 10 minutes. Simply need more training.",
                    },
                    {
                        responseTimeInMs: 15 * 60 * 1000,
                        residentName: residentNames[9],
                        branchName: branchNames[2],
                        staffName: staffNames[2],
                        timestamp: DateTime.now().minus({ days: 4 }).set({ hour: 10, minute: 19 }).toISO(),
                        managerNotes:
                            'Resident accidentally hit the pendent and left the Community for a walk cross the street before Staff reached them.',
                    },
                    {
                        responseTimeInMs: 14 * 60 * 1000,
                        residentName: residentNames[2],
                        branchName: branchNames[3],
                        staffName: staffNames[3],
                        timestamp: DateTime.now().minus({ days: 5 }).set({ hour: 11, minute: 48 }).toISO(),
                        managerNotes: null,
                    },
                    {
                        responseTimeInMs: 13 * 60 * 1000,
                        residentName: residentNames[8],
                        branchName: branchNames[1],
                        staffName: staffNames[4],
                        timestamp: DateTime.now().minus({ days: 6 }).set({ hour: 6, minute: 52 }).toISO(),
                        managerNotes: null,
                    },
                    {
                        responseTimeInMs: 11 * 60 * 1000,
                        branchName: branchNames[4],
                        residentName: residentNames[0],
                        staffName: staffNames[5],
                        timestamp: DateTime.now().minus({ days: 7 }).set({ hour: 12, minute: 19 }).toISO(),
                        managerNotes: null,
                    },
                    {
                        responseTimeInMs: 10 * 60 * 1000,
                        branchName: branchNames[4],
                        residentName: residentNames[7],
                        staffName: staffNames[6],
                        timestamp: DateTime.now().minus({ days: 8 }).set({ hour: 10, minute: 37 }).toISO(),
                        managerNotes: null,
                    },
                ],
            },
            RESIDENTS_WITH_NEEDS_CHANGES: {
                ALL: {
                    regionItems: [
                        {
                            regionName: regionNames[0],
                            residentsWithIncreasedCallsNumber: 8,
                        },
                    ],
                    communityItems: [
                        {
                            residentName: residentNames[8],
                            branchName: branchNames[0],
                            changePercentage: 0.41,
                            callCount: 43,
                            lastAssessmentDate: DateTime.fromISO('2024-12-10').toISODate()!,
                            upcomingAssessmentDate: DateTime.fromISO('2025-03-10').toISODate()!,
                            careLevel: 2,
                            allieHealthNotes: 'Falls 3x last 30 days, 5% weight loss in last 30 days',
                        },
                        {
                            residentName: residentNames[6],
                            branchName: branchNames[5],
                            changePercentage: 0.31,
                            callCount: 36,
                            lastAssessmentDate: DateTime.fromISO('2024-12-12').toISODate()!,
                            upcomingAssessmentDate: DateTime.fromISO('2025-03-12').toISODate()!,
                            careLevel: 1,
                            allieHealthNotes: '2-person assist in PRN',
                        },
                        {
                            residentName: residentNames[0],
                            branchName: branchNames[0],
                            changePercentage: 0.28,
                            callCount: 31,
                            lastAssessmentDate: DateTime.fromISO('2025-01-01').toISODate()!,
                            upcomingAssessmentDate: DateTime.fromISO('2025-03-02').toISODate()!,
                            careLevel: 4,
                            allieHealthNotes: 'Incontinence assist in PRN',
                        },
                        {
                            residentName: residentNames[1],
                            branchName: branchNames[1],
                            changePercentage: 0.68,
                            callCount: 29,
                            lastAssessmentDate: DateTime.fromISO('2024-10-14').toISODate()!,
                            upcomingAssessmentDate: DateTime.fromISO('2025-01-12').toISODate()!,
                            careLevel: 2,
                            allieHealthNotes: 'Antibiotic added in last 7 days',
                        },
                        {
                            residentName: residentNames[2],
                            branchName: branchNames[2],
                            changePercentage: 0.71,
                            callCount: 16,
                            lastAssessmentDate: DateTime.fromISO('2024-11-05').toISODate()!,
                            upcomingAssessmentDate: DateTime.fromISO('2025-02-03').toISODate()!,
                            careLevel: 1,
                            allieHealthNotes: 'Long hospital stay in last 30 days',
                        },
                        {
                            residentName: residentNames[5],
                            branchName: branchNames[3],
                            changePercentage: -0.42,
                            callCount: 12,
                            lastAssessmentDate: DateTime.fromISO('2025-01-22').toISODate()!,
                            upcomingAssessmentDate: DateTime.fromISO('2025-04-2').toISODate()!,
                            careLevel: 3,
                            allieHealthNotes: 'Increase on refusal',
                        },
                        {
                            residentName: residentNames[9],
                            branchName: branchNames[2],
                            changePercentage: -0.53,
                            callCount: 10,
                            lastAssessmentDate: DateTime.fromISO('2024-09-09').toISODate()!,
                            upcomingAssessmentDate: DateTime.fromISO('2024-12-08').toISODate()!,
                            careLevel: 2,
                            allieHealthNotes: '7.5% weight loss in last 60 days',
                        },
                        {
                            residentName: residentNames[7],
                            branchName: branchNames[4],
                            changePercentage: -0.7,
                            callCount: 9,
                            lastAssessmentDate: DateTime.fromISO('2024-08-07').toISODate()!,
                            upcomingAssessmentDate: DateTime.fromISO('2024-11-05').toISODate()!,
                            careLevel: 3,
                            allieHealthNotes: 'Eating refusal in last 3 days',
                        },
                    ],
                },
            },
            TOTAL_CALLS: {
                ALL: {
                    regionItems: [
                        {
                            regionName: regionNames[0],
                            totalCount: 500,
                            totalCountChangePercentage: 0.35,
                            emergencyCount: 200,
                            emergencyChangePercentage: 0.4,
                            regularCount: 300,
                            regularChangePercentage: 0.2,
                        },
                    ],
                    communityItems: [
                        {
                            branchName: branchNames[0],
                            totalCount: 20,
                            totalCountChangePercentage: 0.15,
                            emergencyCount: 10,
                            emergencyChangePercentage: 0.2,
                            regularCount: 10,
                            regularChangePercentage: 0.4,
                        },
                        {
                            branchName: branchNames[1],
                            totalCount: 10,
                            totalCountChangePercentage: 0.15,
                            emergencyCount: 10,
                            emergencyChangePercentage: 0.2,
                            regularCount: 10,
                            regularChangePercentage: 0.4,
                        },
                        {
                            branchName: branchNames[2],
                            totalCount: 10,
                            totalCountChangePercentage: 0.15,
                            emergencyCount: 10,
                            emergencyChangePercentage: 0.2,
                            regularCount: 10,
                            regularChangePercentage: 0.4,
                        },
                        {
                            branchName: branchNames[3],
                            totalCount: 10,
                            totalCountChangePercentage: 0.15,
                            emergencyCount: 10,
                            emergencyChangePercentage: 0.2,
                            regularCount: 10,
                            regularChangePercentage: 0.4,
                        },
                        {
                            branchName: branchNames[4],
                            totalCount: 10,
                            totalCountChangePercentage: 0.15,
                            emergencyCount: 10,
                            emergencyChangePercentage: 0.2,
                            regularCount: 10,
                            regularChangePercentage: 0.4,
                        },
                        {
                            branchName: branchNames[5],
                            totalCount: 10,
                            totalCountChangePercentage: 0.15,
                            emergencyCount: 10,
                            emergencyChangePercentage: 0.2,
                            regularCount: 10,
                            regularChangePercentage: 0.4,
                        },
                        {
                            branchName: branchNames[6],
                            totalCount: 10,
                            totalCountChangePercentage: 0.15,
                            emergencyCount: 10,
                            emergencyChangePercentage: 0.2,
                            regularCount: 10,
                            regularChangePercentage: 0.4,
                        },
                        {
                            branchName: branchNames[7],
                            totalCount: 10,
                            totalCountChangePercentage: 0.15,
                            emergencyCount: 10,
                            emergencyChangePercentage: 0.2,
                            regularCount: 10,
                            regularChangePercentage: 0.4,
                        },
                        {
                            branchName: branchNames[8],
                            totalCount: 10,
                            totalCountChangePercentage: 0.15,
                            emergencyCount: 10,
                            emergencyChangePercentage: 0.2,
                            regularCount: 10,
                            regularChangePercentage: 0.4,
                        },
                        {
                            branchName: branchNames[9],
                            totalCount: 10,
                            totalCountChangePercentage: 0.15,
                            emergencyCount: 10,
                            emergencyChangePercentage: 0.2,
                            regularCount: 10,
                            regularChangePercentage: 0.4,
                        },
                    ],
                },
            },
            INCIDENTS: {
                ALL: {
                    regionItems: [
                        {
                            regionName: regionNames[0],
                            fallCount: 10,
                            onFloorCount: 3,
                            altercationCount: 20,
                        },
                    ],
                    communityItems: [
                        {
                            branchName: branchNames[0],
                            fallCount: 3,
                            onFloorCount: 2,
                            altercationCount: 5,
                        },
                        {
                            branchName: branchNames[1],
                            fallCount: 3,
                            onFloorCount: 1,
                            altercationCount: 3,
                        },
                        {
                            branchName: branchNames[2],
                            fallCount: 2,
                            onFloorCount: 0,
                            altercationCount: 4,
                        },
                        {
                            branchName: branchNames[3],
                            fallCount: 1,
                            onFloorCount: 0,
                            altercationCount: 4,
                        },
                        {
                            branchName: branchNames[4],
                            fallCount: 1,
                            onFloorCount: 0,
                            altercationCount: 4,
                        },
                    ],
                },
            },
            MEDICATION: {
                ALL: {
                    regionItems: [
                        {
                            regionName: regionNames[0],
                            unavailableCount: 10,
                            refusedCount: 20,
                        },
                    ],
                    communityItems: [
                        {
                            branchName: branchNames[5],
                            unavailableCount: 5,
                            refusedCount: 9,
                        },
                        {
                            branchName: branchNames[3],
                            unavailableCount: 3,
                            refusedCount: 7,
                        },
                        {
                            branchName: branchNames[2],
                            unavailableCount: 1,
                            refusedCount: 3,
                        },
                        {
                            branchName: branchNames[6],
                            unavailableCount: 1,
                            refusedCount: 1,
                        },
                    ],
                },
            },
            TRANSFERS: {
                ALL: {
                    regionItems: [
                        {
                            regionName: regionNames[0],
                            nineOneOneCount: 12,
                            medicalCount: 15,
                        },
                    ],
                    communityItems: [
                        {
                            branchName: branchNames[0],
                            nineOneOneCount: 2,
                            medicalCount: 4,
                        },
                        {
                            branchName: branchNames[1],
                            nineOneOneCount: 3,
                            medicalCount: 2,
                        },
                        {
                            branchName: branchNames[2],
                            nineOneOneCount: 2,
                            medicalCount: 3,
                        },
                        {
                            branchName: branchNames[3],
                            nineOneOneCount: 2,
                            medicalCount: 2,
                        },
                        {
                            branchName: branchNames[4],
                            nineOneOneCount: 1,
                            medicalCount: 2,
                        },
                        {
                            branchName: branchNames[6],
                            nineOneOneCount: 1,
                            medicalCount: 1,
                        },
                        {
                            branchName: branchNames[5],
                            nineOneOneCount: 1,
                            medicalCount: 1,
                        },
                    ],
                },
            },
            DOCUMENTATION_RATE: {
                ALL: [
                    {
                        branchName: branchNames[0],
                        scheduledDocumentationCount: 10,
                        scheduledDocumentationChangePercentage: 0.1,
                        scheduledNotDocumentedCount: 2,
                        scheduledNotDocumentedChangePercentage: 0.2,
                        ecallDocumentationPercentage: 0.3,
                        totalCallsCount: 12,
                    },
                    {
                        branchName: branchNames[1],
                        scheduledDocumentationCount: 10,
                        scheduledDocumentationChangePercentage: 0.1,
                        scheduledNotDocumentedCount: 2,
                        scheduledNotDocumentedChangePercentage: 0.2,
                        ecallDocumentationPercentage: 0.3,
                        totalCallsCount: 12,
                    },
                    {
                        branchName: branchNames[2],
                        scheduledDocumentationCount: 10,
                        scheduledDocumentationChangePercentage: 0.1,
                        scheduledNotDocumentedCount: 2,
                        scheduledNotDocumentedChangePercentage: 0.2,
                        ecallDocumentationPercentage: 0.3,
                        totalCallsCount: 12,
                    },
                    {
                        branchName: branchNames[3],
                        scheduledDocumentationCount: 10,
                        scheduledDocumentationChangePercentage: 0.1,
                        scheduledNotDocumentedCount: 2,
                        scheduledNotDocumentedChangePercentage: 0.2,
                        ecallDocumentationPercentage: 0.3,
                        totalCallsCount: 12,
                    },
                    {
                        branchName: branchNames[4],
                        scheduledDocumentationCount: 10,
                        scheduledDocumentationChangePercentage: 0.1,
                        scheduledNotDocumentedCount: 2,
                        scheduledNotDocumentedChangePercentage: 0.2,
                        ecallDocumentationPercentage: 0.3,
                        totalCallsCount: 12,
                    },
                    {
                        branchName: branchNames[5],
                        scheduledDocumentationCount: 10,
                        scheduledDocumentationChangePercentage: 0.1,
                        scheduledNotDocumentedCount: 2,
                        scheduledNotDocumentedChangePercentage: 0.2,
                        ecallDocumentationPercentage: 0.3,
                        totalCallsCount: 12,
                    },
                ],
            },
            OVERDUE_ASSESSMENTS_AND_INCOMPLETE_FALLS: {
                ALL: {
                    regionItems: [
                        {
                            regionName: regionNames[0],
                            overdueCount: 10,
                            fallDocumentationIncompleteCount: 3,
                        },
                    ],
                    communityItems: [
                        {
                            branchName: branchNames[0],
                            overdueCount: 4,
                            fallDocumentationIncompleteCount: 1,
                        },
                        {
                            branchName: branchNames[1],
                            overdueCount: 2,
                            fallDocumentationIncompleteCount: 2,
                        },
                        {
                            branchName: branchNames[2],
                            overdueCount: 3,
                            fallDocumentationIncompleteCount: 0,
                        },
                        {
                            branchName: branchNames[3],
                            overdueCount: 1,
                            fallDocumentationIncompleteCount: 0,
                        },
                    ],
                },
            },
        },
        LABOR: {
            SCHEDULED_VS_BUDGETED_HOURS: {
                ALL: {
                    regionItems: [
                        {
                            regionName: regionNames[0],
                            scheduledMinusBudgetedHours: +424,
                            scheduledHours: 12283,
                            budgetedHours: 12707,
                        },
                    ],
                    communityItems: [
                        {
                            branchName: branchNames[0],
                            scheduledMinusBudgetedHours: 152,
                            scheduledHours: 1120,
                            budgetedHours: 968,
                        },
                        {
                            branchName: branchNames[1],
                            scheduledMinusBudgetedHours: 127,
                            scheduledHours: 1456,
                            budgetedHours: 1329,
                        },
                        {
                            branchName: branchNames[2],
                            scheduledMinusBudgetedHours: 111,
                            scheduledHours: 1429,
                            budgetedHours: 1318,
                        },
                        {
                            branchName: branchNames[3],
                            scheduledMinusBudgetedHours: 64,
                            scheduledHours: 1222,
                            budgetedHours: 1158,
                        },
                        {
                            branchName: branchNames[4],
                            scheduledMinusBudgetedHours: 0,
                            scheduledHours: 1282,
                            budgetedHours: 1282,
                        },
                        {
                            branchName: branchNames[5],
                            scheduledMinusBudgetedHours: -2,
                            scheduledHours: 1039,
                            budgetedHours: 1041,
                        },
                        {
                            branchName: branchNames[6],
                            scheduledMinusBudgetedHours: -5,
                            scheduledHours: 1439,
                            budgetedHours: 1444,
                        },
                        {
                            branchName: branchNames[7],
                            scheduledMinusBudgetedHours: -5,
                            scheduledHours: 1520,
                            budgetedHours: 1525,
                        },
                        {
                            branchName: branchNames[8],
                            scheduledMinusBudgetedHours: -8,
                            scheduledHours: 1202,
                            budgetedHours: 1210,
                        },
                        {
                            branchName: branchNames[9],
                            scheduledMinusBudgetedHours: -10,
                            scheduledHours: 998,
                            budgetedHours: 1008,
                        },
                    ],
                },
            },
            OVERTIME_AND_AGENCY_SCHEDULED: {
                ALL: {
                    regionItems: [
                        {
                            regionName: regionNames[0],
                            overtimeScheduled: 486,
                            agencyScheduled: 186,
                        },
                    ],
                    communityItems: [
                        {
                            branchName: branchNames[0],
                            overtimeScheduled: 24,
                            agencyScheduled: 80,
                        },
                        {
                            branchName: branchNames[1],
                            overtimeScheduled: 102,
                            agencyScheduled: 8,
                        },
                        {
                            branchName: branchNames[2],
                            overtimeScheduled: 72,
                            agencyScheduled: 32,
                        },
                        {
                            branchName: branchNames[3],
                            overtimeScheduled: 48,
                            agencyScheduled: 56,
                        },
                        {
                            branchName: branchNames[4],
                            overtimeScheduled: 64,
                            agencyScheduled: 0,
                        },
                        {
                            branchName: branchNames[5],
                            overtimeScheduled: 56,
                            agencyScheduled: 0,
                        },
                        {
                            branchName: branchNames[6],
                            overtimeScheduled: 36,
                            agencyScheduled: 10,
                        },
                        {
                            branchName: branchNames[7],
                            overtimeScheduled: 36,
                            agencyScheduled: 0,
                        },
                        {
                            branchName: branchNames[8],
                            overtimeScheduled: 28,
                            agencyScheduled: 0,
                        },
                        {
                            branchName: branchNames[9],
                            overtimeScheduled: 20,
                            agencyScheduled: 0,
                        },
                    ],
                },
            },
            HIRING_NEEDS: {
                ALL: {
                    regionItems: [
                        {
                            regionName: regionNames[0],
                            hiresNeededCount: 32,
                        },
                    ],
                    communityItems: [
                        {
                            branchName: branchNames[0],
                            hiresNeededCount: 9,
                        },
                        {
                            branchName: branchNames[1],
                            hiresNeededCount: 6,
                        },
                        {
                            branchName: branchNames[2],
                            hiresNeededCount: 5,
                        },
                        {
                            branchName: branchNames[3],
                            hiresNeededCount: 3,
                        },
                        {
                            branchName: branchNames[4],
                            hiresNeededCount: 1,
                        },
                        {
                            branchName: branchNames[5],
                            hiresNeededCount: 0,
                        },
                    ],
                },
            },
        },
        CARE_REVENUE: {
            RESIDENT_CHANGES: {
                RESIDENTS_WITH_NEEDS_CHANGES: {
                    regionItems: [{ regionName: regionNames[0], increasedCallsPercentage: 8 }],
                    communityItems: [
                        {
                            residentName: residentNames[0],
                            branchName: branchNames[0],
                            changePercentage: 0.41,
                            callCount: 43,
                            lastAssessmentDate: DateTime.fromISO('2024-12-10').toISODate()!,
                            upcomingAssessmentDate: DateTime.fromISO('2025-03-10').toISODate()!,
                            careLevel: 2,
                            allieHealthNotes: 'Falls 3x last 30 days, 5% weight loss in last 30 days',
                        },
                        {
                            residentName: residentNames[1],
                            branchName: branchNames[5],
                            changePercentage: 0.31,
                            callCount: 36,
                            lastAssessmentDate: DateTime.fromISO('2024-12-12').toISODate()!,
                            upcomingAssessmentDate: DateTime.fromISO('2025-03-12').toISODate()!,
                            careLevel: 1,
                            allieHealthNotes: '2-person assist in PRN',
                        },
                        {
                            residentName: residentNames[2],
                            branchName: branchNames[0],
                            changePercentage: 0.28,
                            callCount: 31,
                            lastAssessmentDate: DateTime.fromISO('2025-01-01').toISODate()!,
                            upcomingAssessmentDate: DateTime.fromISO('2025-03-02').toISODate()!,
                            careLevel: 4,
                            allieHealthNotes: 'Incontinence assist in PRN',
                        },
                        {
                            residentName: residentNames[3],
                            branchName: branchNames[1],
                            changePercentage: 0.68,
                            callCount: 29,
                            lastAssessmentDate: DateTime.fromISO('2024-10-14').toISODate()!,
                            upcomingAssessmentDate: DateTime.fromISO('2025-01-12').toISODate()!,
                            careLevel: 2,
                            allieHealthNotes: 'Antibiotic added in last 7 days',
                        },
                        {
                            residentName: residentNames[4],
                            branchName: branchNames[2],
                            changePercentage: 0.71,
                            callCount: 16,
                            lastAssessmentDate: DateTime.fromISO('2024-11-05').toISODate()!,
                            upcomingAssessmentDate: DateTime.fromISO('2025-02-03').toISODate()!,
                            careLevel: 1,
                            allieHealthNotes: 'Long hospital stay in last 30 days',
                        },
                        {
                            residentName: residentNames[5],
                            branchName: branchNames[3],
                            changePercentage: -0.42,
                            callCount: 12,
                            lastAssessmentDate: DateTime.fromISO('2025-01-22').toISODate()!,
                            upcomingAssessmentDate: DateTime.fromISO('2025-04-2').toISODate()!,
                            careLevel: 3,
                            allieHealthNotes: 'Increase on refusal',
                        },
                        {
                            residentName: residentNames[6],
                            branchName: branchNames[2],
                            changePercentage: -0.53,
                            callCount: 10,
                            lastAssessmentDate: DateTime.fromISO('2024-09-09').toISODate()!,
                            upcomingAssessmentDate: DateTime.fromISO('2024-12-08').toISODate()!,
                            careLevel: 2,
                            allieHealthNotes: '7.5% weight loss in last 60 days',
                        },
                        {
                            residentName: residentNames[7],
                            branchName: branchNames[4],
                            changePercentage: -0.7,
                            callCount: 9,
                            lastAssessmentDate: DateTime.fromISO('2024-08-07').toISODate()!,
                            upcomingAssessmentDate: DateTime.fromISO('2024-11-05').toISODate()!,
                            careLevel: 3,
                            allieHealthNotes: 'Eating refusal in last 3 days',
                        },
                    ],
                },
                POTENTIAL_CARE_LEVEL_INCREASE: {
                    regionItems: [{ regionName: regionNames[0], residentCount: 6 }],
                    communityItems: [
                        {
                            branchName: branchNames[0],
                            residentName: residentNames[0],
                            callCount: 43,
                            avgTimeSpentPerShiftInMs: 48 * 60 * 1000, // 48 minutes
                            careLevel: 1,
                            upcomingAssessmentDate: '2025-03-10',
                            allieHealthCoCScore: 4,
                            allieHealthNotes: 'Falls 3x last 30 days, 5% weight loss in last 30 days',
                        },
                        {
                            branchName: branchNames[1],
                            residentName: residentNames[1],
                            callCount: 36,
                            avgTimeSpentPerShiftInMs: 48 * 60 * 1000, // 48 minutes
                            careLevel: 1,
                            upcomingAssessmentDate: '2025-05-12',
                            allieHealthCoCScore: 4,
                            allieHealthNotes: '2-person assist in PRN',
                        },
                        {
                            branchName: branchNames[2],
                            residentName: residentNames[2],
                            callCount: 31,
                            avgTimeSpentPerShiftInMs: 48 * 60 * 1000, // 48 minutes
                            careLevel: 1,
                            upcomingAssessmentDate: '2025-04-14',
                            allieHealthCoCScore: 3,
                            allieHealthNotes: 'Long hospital stay, Incontinence refusal',
                        },
                        {
                            branchName: branchNames[3],
                            residentName: residentNames[3],
                            callCount: 29,
                            avgTimeSpentPerShiftInMs: 48 * 60 * 1000, // 48 minutes
                            careLevel: 1,
                            upcomingAssessmentDate: '2025-03-03',
                            allieHealthCoCScore: 2,
                            allieHealthNotes: '7.5% weight loss in last 60 days',
                        },
                        {
                            branchName: branchNames[4],
                            residentName: residentNames[4],
                            callCount: 30,
                            avgTimeSpentPerShiftInMs: 48 * 60 * 1000, // 48 minutes
                            careLevel: 2,
                            upcomingAssessmentDate: '2025-02-19',
                            allieHealthCoCScore: 2,
                            allieHealthNotes: 'Antibiotic added in last 7 days...',
                        },
                        {
                            branchName: branchNames[5],
                            residentName: residentNames[5],
                            callCount: 26,
                            avgTimeSpentPerShiftInMs: 48 * 60 * 1000, // 48 minutes
                            careLevel: 2,
                            upcomingAssessmentDate: '2025-04-22',
                            allieHealthCoCScore: 2,
                            allieHealthNotes: 'Long hospital stays',
                        },
                    ],
                },
            },
            ON_DEMAND_CARE: {
                ALL: {
                    regionItems: [{ regionName: regionNames[0], residentsWithAssistLevelIncreaseCount: 6 }],
                    communityItems: [
                        {
                            branchName: branchNames[0],
                            residentName: residentNames[0],
                            prnCount: 33,
                            adlDeviation: 2,
                            numberOfCalls: 21,
                            careType: 'AL',
                            careLevel: 1,
                            lastAssessmentDate: '2024-12-10',
                            upcomingAssessmentDate: '2025-05-10',
                            onHospice: false,
                        },
                        {
                            branchName: branchNames[1],
                            residentName: residentNames[1],
                            prnCount: 28,
                            adlDeviation: 2,
                            numberOfCalls: 18,
                            careType: 'MC',
                            careLevel: 1,
                            lastAssessmentDate: '2024-12-12',
                            upcomingAssessmentDate: '2025-05-12',
                            onHospice: false,
                        },
                        {
                            branchName: branchNames[2],
                            residentName: residentNames[2],
                            prnCount: 19,
                            adlDeviation: 3,
                            numberOfCalls: 12,
                            careType: 'AL',
                            careLevel: 1,
                            lastAssessmentDate: '2025-01-01',
                            upcomingAssessmentDate: '2025-03-02',
                            onHospice: false,
                        },
                        {
                            branchName: branchNames[3],
                            residentName: residentNames[3],
                            prnCount: 16,
                            adlDeviation: 2,
                            numberOfCalls: 10,
                            careType: 'AL',
                            careLevel: 2,
                            lastAssessmentDate: '2024-10-14',
                            upcomingAssessmentDate: '2025-01-12',
                            onHospice: false,
                        },
                        {
                            branchName: branchNames[4],
                            residentName: residentNames[4],
                            prnCount: 10,
                            adlDeviation: 2,
                            numberOfCalls: 7,
                            careType: 'MC',
                            careLevel: 2,
                            lastAssessmentDate: '2024-11-05',
                            upcomingAssessmentDate: '2025-02-03',
                            onHospice: false,
                        },
                        {
                            branchName: branchNames[5],
                            residentName: residentNames[5],
                            prnCount: 51,
                            adlDeviation: 4,
                            numberOfCalls: 33,
                            careType: 'MC',
                            careLevel: 3,
                            lastAssessmentDate: '2025-01-22',
                            upcomingAssessmentDate: '2025-04-22',
                            onHospice: true,
                        },
                    ],
                },
            },
            CHANGE_OF_CONDITION: {
                ALL: {
                    regionItems: [{ regionName: regionNames[0], residentCount: 6 }],
                    communityItems: [
                        {
                            branchName: branchNames[0],
                            residentName: residentNames[0],
                            numberOfTriggers: 5,
                            careType: 'AL',
                            careLevel: 1,
                            lastAssessmentDate: '2024-12-10',
                            upcomingAssessmentDate: '2025-05-10',
                            onHospice: false,
                        },
                        {
                            branchName: branchNames[1],
                            residentName: residentNames[1],
                            numberOfTriggers: 4,
                            careType: 'MC',
                            careLevel: 1,
                            lastAssessmentDate: '2024-12-12',
                            upcomingAssessmentDate: '2025-05-12',
                            onHospice: false,
                        },
                        {
                            branchName: branchNames[2],
                            residentName: residentNames[2],
                            numberOfTriggers: 3,
                            careType: 'AL',
                            careLevel: 1,
                            lastAssessmentDate: '2025-01-01',
                            upcomingAssessmentDate: '2025-03-02',
                            onHospice: false,
                        },
                        {
                            branchName: branchNames[3],
                            residentName: residentNames[3],
                            numberOfTriggers: 12,
                            careType: 'AL',
                            careLevel: 2,
                            lastAssessmentDate: '2024-10-14',
                            upcomingAssessmentDate: '2025-01-12',
                            onHospice: false,
                        },
                        {
                            branchName: branchNames[4],
                            residentName: residentNames[4],
                            numberOfTriggers: 2,
                            careType: 'MC',
                            careLevel: 2,
                            lastAssessmentDate: '2024-11-05',
                            upcomingAssessmentDate: '2025-02-03',
                            onHospice: false,
                        },
                        {
                            branchName: branchNames[5],
                            residentName: residentNames[5],
                            numberOfTriggers: 2,
                            careType: 'AL',
                            careLevel: 3,
                            lastAssessmentDate: '2025-01-22',
                            upcomingAssessmentDate: '2025-04-22',
                            onHospice: true,
                        },
                    ],
                },
            },
            DISTRIBUTION_OF_LEVELS: {
                ALL: {
                    regionItems: [
                        {
                            regionName: regionNames[0],
                            level1Count: 356,
                            level2Count: 103,
                            level3Count: 95,
                            level4Count: 46,
                            level1Percentage: 0.59,
                            level2Percentage: 0.17,
                            level3Percentage: 0.16,
                            level4Percentage: 0.08,
                        },
                    ],
                    communityItems: [
                        {
                            branchName: branchNames[0],
                            level1Count: 56,
                            level2Count: 22,
                            level3Count: 20,
                            level4Count: 2,
                            level1Percentage: 0.56,
                            level2Percentage: 0.22,
                            level3Percentage: 0.2,
                            level4Percentage: 0.02,
                        },
                        {
                            branchName: branchNames[1],
                            level1Count: 58,
                            level2Count: 21,
                            level3Count: 20,
                            level4Count: 1,
                            level1Percentage: 0.58,
                            level2Percentage: 0.21,
                            level3Percentage: 0.2,
                            level4Percentage: 0.01,
                        },
                        {
                            branchName: branchNames[2],
                            level1Count: 45,
                            level2Count: 19,
                            level3Count: 15,
                            level4Count: 21,
                            level1Percentage: 0.45,
                            level2Percentage: 0.19,
                            level3Percentage: 0.15,
                            level4Percentage: 0.21,
                        },
                        {
                            branchName: branchNames[3],
                            level1Count: 49,
                            level2Count: 17,
                            level3Count: 21,
                            level4Count: 13,
                            level1Percentage: 0.49,
                            level2Percentage: 0.17,
                            level3Percentage: 0.21,
                            level4Percentage: 0.13,
                        },
                        {
                            branchName: branchNames[4],
                            level1Count: 69,
                            level2Count: 13,
                            level3Count: 14,
                            level4Count: 4,
                            level1Percentage: 0.69,
                            level2Percentage: 0.13,
                            level3Percentage: 0.14,
                            level4Percentage: 0.04,
                        },
                        {
                            branchName: branchNames[5],
                            level1Count: 79,
                            level2Count: 11,
                            level3Count: 5,
                            level4Count: 5,
                            level1Percentage: 0.79,
                            level2Percentage: 0.11,
                            level3Percentage: 0.05,
                            level4Percentage: 0.05,
                        },
                    ],
                },
            },
        },
    },
};

export const getDetailsData = <
    L extends Level,
    M extends ModuleType<L>,
    D extends DetailsType<L, M>,
    G extends DetailsGroupingType<L, M, D>,
    T extends DetailsGroupingDataType<L, M, D, G>,
>(
    payload: {
        level: L;
        moduleType: M;
        detailsType: D;
        detailsGroupingType: G;
    } | null
) => {
    if (!payload) return null;

    const { level, moduleType, detailsType, detailsGroupingType } = payload;

    const levelData = testDetailsData[level]!;
    const moduleData = levelData[moduleType]!;
    const detailsData = moduleData[detailsType]!;
    const detailsGroupingData = detailsData[detailsGroupingType];

    return detailsGroupingData as T;
};
