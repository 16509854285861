import { Box, Stack, Typography, alpha, styled, useTheme } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';

import { SectionDataType } from '@allie/operations-common/src/types/module';

import DataContainer from '~/pages/OperationsV2/components/Module/Section/DataContainer';
import DataSimpleBarChart, {
    DataSimpleBarChartColorMapping,
} from '~/pages/OperationsV2/components/Module/Section/recharts/DataSimpleBarChart';
import { formatPercentage } from '~/pages/OperationsV2/utils/format';

type Item = SectionDataType<'REGION', 'RISK_AND_COMPLIANCE', 'ECALL_DOCUMENTATION_RATE'>['items'][number];

const ChartContainer = styled(Box)(({ theme: { palette } }) => ({
    backgroundColor: palette.primary[100] as string,
    width: '100%',
    padding: '24px 24px 6px',
    borderRadius: '16px',
    overflowX: 'auto',
}));

const Data = ({ items }: { items: Item[] }) => {
    const { palette } = useTheme();

    const [selectedBranch, setSelectedBranch] = useState<Item | undefined>(items[0]);

    const isSelected = useCallback((item: Item) => item.branchId === selectedBranch?.branchId, [selectedBranch]);
    const onSelect = useCallback((item: Item) => setSelectedBranch(item), [setSelectedBranch]);

    const colorMapping: DataSimpleBarChartColorMapping = useMemo(
        () => ({
            selected: [palette.primary[500] as string],
            unselected: [alpha(palette.grey[900], 0.1)],
        }),
        [palette]
    );

    if (!selectedBranch) return null;

    return (
        <DataContainer sx={{ gap: '12px' }}>
            <Stack
                sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                }}
            >
                <Typography>{selectedBranch.branchName}</Typography>
                <Typography>{formatPercentage(selectedBranch.ratePercentage)}</Typography>
            </Stack>
            <ChartContainer>
                <DataSimpleBarChart
                    height={200}
                    items={items}
                    xAxisDataKey="branchName"
                    yAxisDataKeys={['ratePercentage']}
                    colorMapping={colorMapping}
                    isSelected={isSelected}
                    onSelect={onSelect}
                />
            </ChartContainer>
        </DataContainer>
    );
};

export default Data;
