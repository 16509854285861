import { DateTime } from 'luxon';
import { useCallback } from 'react';

import { api } from '~/api';
import { useBranchId, useErrorHandledQuery, useToken } from '~/api/common';
import { DocumentedCall, DocumentedCallsResponse } from '~/types/call/call';

export type RemappedDocumentedCall = ReturnType<typeof remapCalls>;

type UseGetDocumentedCallsParams = {
    enabled?: boolean;
    shiftDay?: DateTime;
    shiftId?: number;
    careLocationIds?: number[];
};

export const useGetDocumentedCalls = ({ enabled, shiftDay, shiftId, careLocationIds }: UseGetDocumentedCallsParams) => {
    const token = useToken();
    const branchId = useBranchId();

    const queryFn = useCallback(async () => {
        const options = {
            headers: { authorization: token },
            params: {
                shiftDay: shiftDay?.toFormat('yyyy-MM-dd'),
                shiftId,
                careLocationIds: careLocationIds?.join(',') || undefined,
            },
        };

        const { data } = await api.get<DocumentedCallsResponse>(`/e-call/${branchId}/care/documented-calls`, options);

        return data.response.map(remapCalls);
    }, [token, branchId, shiftDay, shiftId, careLocationIds]);

    return useErrorHandledQuery({
        queryKey: ['documented-calls', branchId, shiftDay, shiftId, careLocationIds],
        queryFn,
        staleTime: 300000,
        enabled: enabled && !!branchId && !!shiftDay && !!shiftId,
    });
};

const remapCalls = ({ triggeredAt, attendedAt, resident, attendedByUser, ...call }: DocumentedCall) => ({
    ...call,
    triggeredAt: fromISO(triggeredAt),
    attendedAt: fromISO(attendedAt),
    resident: resident ? remapPerson(resident) : null,
    attendedByUser: remapPerson(attendedByUser),
});

const remapPerson = <T extends { firstName: string; lastName: string }>(person: T) => ({
    ...person,
    name: `${person.firstName} ${person.lastName}`,
});

const fromISO = (date: string) => DateTime.fromISO(date, { setZone: true }) as DateTime<true>;
