import { Box, Button, CircularProgress, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';

import { useTimer } from '~/hooks/useTimer';
import { UnclaimedLevel } from '~/types/call/call';

const UnclaimedButtonContainer = styled(Box)(({ theme }) => ({
    padding: '16px',
    borderRadius: '8px',
    gap: '12px',
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('sm')]: {
        margin: 0,
        padding: 0,
        flexDirection: 'row',
    },

    [theme.breakpoints.between('sm', 'md')]: {
        flexDirection: 'column',
        gap: 0,
        paddingBottom: '16px',
    },
}));

interface UnclaimedButtonProps {
    calledAt: DateTime;
    unclaimedLevel: UnclaimedLevel;
    isLoading?: boolean;
    onClick: () => void;
}

export const UnclaimedButton = ({ calledAt, unclaimedLevel, isLoading, onClick }: UnclaimedButtonProps) => {
    const timer = useTimer(calledAt);
    const { palette, breakpoints } = useTheme();
    const isDesktop = useMediaQuery(breakpoints.up('sm'));

    const styles =
        unclaimedLevel === UnclaimedLevel.Level3
            ? {
                  backgroundColor: palette.error[50] as string,
                  border: `1px solid ${palette.error[900] as string}`,
              }
            : {
                  backgroundColor: palette.warning[200] as string,
                  border: `1px solid ${palette.warning[500]}`,
              };

    return (
        <UnclaimedButtonContainer sx={styles}>
            <Box sx={isDesktop ? { flex: 1, padding: '16px' } : undefined}>
                <Typography
                    variant="body1"
                    fontWeight={700}
                    color={
                        unclaimedLevel === UnclaimedLevel.Level3 ? (palette.error[900] as string) : palette.grey[900]
                    }
                >
                    Call
                </Typography>
                <Typography
                    variant="body2"
                    color={
                        unclaimedLevel === UnclaimedLevel.Level3 ? (palette.error[900] as string) : palette.grey[900]
                    }
                >
                    Resident has been waiting for <span style={{ fontWeight: 700 }}>{timer}</span>
                </Typography>
            </Box>
            <Button
                sx={[
                    { minWidth: '150px' },
                    isDesktop ? { margin: '16px', maxHeight: '46px', marginBlock: 'auto' } : {},
                ]}
                variant={unclaimedLevel === UnclaimedLevel.Level3 ? 'containedError' : 'warning'}
                onClick={onClick}
            >
                {isLoading ? <CircularProgress size={20} thickness={4} sx={{ color: 'white' }} /> : 'Claim'}
            </Button>
        </UnclaimedButtonContainer>
    );
};
