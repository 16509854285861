import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { PiArrowsHorizontalFill } from 'react-icons/pi';
import { useSelector } from 'react-redux';

import { CALL_STATUS } from '@allie/utils/src/constants/ecall/call.constants';
import { DEVICE_LOCATION_TYPE } from '@allie/utils/src/constants/ecall/device.constants';

import { CustomAvatar } from '~/components/Custom';
import { ReduxStore } from '~/types/redux';

import { getPublicLocationCallIcon } from '../mapCallIcon';

import { StatusBadge } from './StatusBadge';
import { CallCardContent, CallCardProps } from './shared';

interface CardContentProps extends CallCardProps {
    onClick?: () => void;
    showDetails?: boolean;
}

export const CardContent = (props: CardContentProps) => {
    const { palette, breakpoints } = useTheme();
    const isDesktop = useMediaQuery(breakpoints.up('sm'));

    const { userId } = useSelector((state: ReduxStore) => state.session.sessionData);

    const callOwner = props.claimedByUser ?? props.attendedByUser;
    const claimedByUser = callOwner?.id === userId;
    const isOpenCall = props.status === CALL_STATUS.OPEN;

    const callOwnerName = useMemo(() => {
        return claimedByUser ? 'Me' : `${callOwner?.firstName} ${callOwner?.lastName.charAt(0)}`;
    }, [claimedByUser, callOwner]);

    const callLocationName = useMemo(() => {
        if (props.deviceLocation?.type !== DEVICE_LOCATION_TYPE.PRIVATE_ROOM) {
            return props.deviceLocation?.name;
        }

        if (isOpenCall) {
            return `${props.resident?.firstName} ${props.resident?.lastName}`;
        }

        return `${props.resident?.firstName} ${props.resident?.lastName.charAt(0)}.`;
    }, [isOpenCall, props.deviceLocation, props.resident]);

    const renderChevron = () => {
        if (isDesktop) return null;

        if (isOpenCall || !claimedByUser) return null;

        return props.showDetails ? (
            <FaChevronUp size={20} color={palette.grey[300]} />
        ) : (
            <FaChevronDown size={20} color={palette.grey[300]} />
        );
    };

    const callRoomNumber = useMemo(() => {
        if (props.resident?.roomNumber) {
            return `Apt ${props.resident?.roomNumber}`;
        }

        return props.deviceLocation?.roomNumber ?? props.deviceLocation?.zoneName;
    }, [props.deviceLocation, props.resident]);

    return (
        <CallCardContent onClick={props.onClick} showDetails={!!props.showDetails}>
            {props.deviceLocation?.type === DEVICE_LOCATION_TYPE.PRIVATE_ROOM ? (
                <CustomAvatar
                    firstName={props.resident?.firstName}
                    lastName={props.resident?.lastName}
                    photo={props.resident?.photo ?? ''}
                    size={64}
                />
            ) : (
                <Box bgcolor={palette.grey[25]} borderRadius="32px" padding="16px">
                    {getPublicLocationCallIcon(props.deviceLocation?.type)}
                </Box>
            )}
            <Box display="flex" flex={1} flexDirection="column">
                <Box flex={1} display="flex" gap="4px" alignItems="center">
                    <Typography fontSize="16px" color={palette.grey[900]} textAlign="left" fontWeight={700}>
                        {callLocationName}
                    </Typography>
                    {!isOpenCall && (
                        <>
                            <PiArrowsHorizontalFill size={16} color={palette.grey[900]} />
                            <Typography color={palette.grey[900]} textAlign="left" fontWeight={400}>
                                {callOwnerName}
                            </Typography>
                        </>
                    )}
                </Box>
                <Typography color={palette.secondary.main} fontWeight={700}>
                    {callRoomNumber}
                </Typography>
                {!isOpenCall && (
                    <StatusBadge
                        calledAt={props.triggeredAt}
                        status={props.status}
                        attendedAt={props.attendedAt ?? undefined}
                        claimedByUser={claimedByUser}
                    />
                )}
            </Box>
            {renderChevron()}
        </CallCardContent>
    );
};
