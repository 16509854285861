import { Box, useTheme } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { CALL_STATUS } from '@allie/utils/src/constants/ecall/call.constants';

import { ReduxStore } from '~/types/redux';

import { CallDetails } from '../CallDetails';

import { CardContent } from './CardContent';
import { ReopenedCallBanner } from './ReopenedCallBanner';
import { StatusBadge } from './StatusBadge';
import { UnclaimedButton } from './UnclaimedButton';
import { CallCardContainer, CallCardContentContainer, CallCardProps } from './shared';

export const DesktopCallCard = (props: CallCardProps) => {
    const { claimCall, isLoading } = props;
    const { palette } = useTheme();

    const { userId } = useSelector((state: ReduxStore) => state.session.sessionData);

    const callOwner = props.claimedByUser ?? props.attendedByUser;
    const claimedByUser = callOwner?.id === userId;
    const isOpenCall = props.status === CALL_STATUS.OPEN;

    const handleClaimCall = async () => {
        await claimCall({ callId: props.id });
    };

    return (
        <CallCardContainer>
            <CallCardContentContainer bgcolor={!isOpenCall && !claimedByUser ? palette.grey[50] : undefined}>
                <CardContent {...props} />

                {isOpenCall && (
                    <Box display="flex" flexDirection="column" gap="8px" flex={1}>
                        {props.hasBeenReopened && <ReopenedCallBanner />}
                        <UnclaimedButton
                            calledAt={props.triggeredAt}
                            unclaimedLevel={props.level}
                            onClick={handleClaimCall}
                            isLoading={isLoading}
                        />
                    </Box>
                )}

                {!isOpenCall && !!props.deviceLocation?.type && (
                    <CallDetails
                        callId={props.id}
                        claimedByUser={claimedByUser}
                        status={props.status}
                        resident={props.resident}
                        suggestedLocations={undefined} // TODO - add suggested locations
                        calledAt={props.triggeredAt}
                        startedAt={props.attendedAt ?? undefined}
                        type={props.deviceLocation.type}
                        deviceLocation={props.deviceLocation}
                    />
                )}

                {!isOpenCall && !claimedByUser && (
                    <Box flex={1} height="100%" display="flex" alignItems="center">
                        <StatusBadge
                            calledAt={props.triggeredAt}
                            status={props.status}
                            attendedAt={props.attendedAt ?? undefined}
                            claimedByUser={false}
                        />
                    </Box>
                )}
            </CallCardContentContainer>
        </CallCardContainer>
    );
};
